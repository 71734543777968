import React from "react";
import { Dashboard } from "../screens/dashboard/Dashboard";

import {
	ADD_COLLABOARTORS_PATH,
	ADD_EVENT_PATH,
	ADD_PRODUCT_PATH,
	ADD_PROMO_CODE_PATH,
	COLLABOARTOR_DETAILS_PATH,
	COLLABOARTORS_PATH,
	COMMUNITY_PATH,
	DASHBOARD_PATH,
	EVENT_PATH,
	FINANCE_MANAGEMENT_PATH,
	MERCHANDISE_PATH,
	MUSIC_MANAGEMENT_PATH,
	MY_PROFILE_PATH,
	ORDER_MANAGEMENT_PATH,
	PRODUCT_ADD_PATH_2,
	PROMO_CODE_PATH,
	USER_MANAGEMENT_PATH,
	VIEW_EVENT_PATH,
	VIEW_ORDER_PATH,
} from "../utils/constants/routes";
import { Merchandise } from "../screens/merchandise/Merchandise";
import Collaborators from "../screens/collaborators/Collaborators";
import { OrderManagement } from "../screens/ordermanagement/OrderManagement";
import { ViewOrder } from "../screens/ordermanagement/ViewOrder";
import { Events } from "../screens/events/Events";
import { ViewEvent } from "../screens/events/ViewEvent";
import { AddEvent } from "../screens/events/AddEvent";
import { Community } from "../screens/community/Community";
import { MusicManagement } from "../screens/musicmanagement/MusicManagement";
import { FinanceManagement } from "../screens/financemanagement/FinanceManagement";
import UserManagement from "../screens/user/UserManagement";
import PromoCode from "../screens/promocode/PromoCode";
import { AddPromoCode } from "../screens/promocode/AddPromoCode";
import { AddProduct } from "../screens/merchandise/AddProduct";
import MyProfile from "../screens/myprofile/MyProfile";
import AddCollaborators from "../screens/collaborators/AddCollaboartors";
import CollaboratorDetails from "../screens/collaborators/CollaboratorDetails";
export const userRoutes = [
	{
		path: DASHBOARD_PATH,
		component: Dashboard,
	},
	{
		path: USER_MANAGEMENT_PATH,
		component: UserManagement,
	},
	{
		path: COLLABOARTORS_PATH,
		component: Collaborators,
	},
	{
		path: COLLABOARTOR_DETAILS_PATH,
		component: CollaboratorDetails,
	},
	{
		path: ADD_COLLABOARTORS_PATH,
		component: AddCollaborators,
	},
	{
		path: MERCHANDISE_PATH,
		component: Merchandise,
	},
	{
		path: ADD_PRODUCT_PATH,
		component: AddProduct,
	},

	{
		path: ORDER_MANAGEMENT_PATH,
		component: OrderManagement,
	},
	{
		path: VIEW_ORDER_PATH,
		component: ViewOrder,
	},
	{
		path: EVENT_PATH,
		component: Events,
	},
	{
		path: ADD_EVENT_PATH,
		component: AddEvent,
	},
	{
		path: VIEW_EVENT_PATH,
		component: ViewEvent,
	},
	{
		path: COMMUNITY_PATH,
		component: Community,
	},
	{
		path: MUSIC_MANAGEMENT_PATH,
		component: MusicManagement,
	},
	{
		path: FINANCE_MANAGEMENT_PATH,
		component: FinanceManagement,
	},


	{
		path: PROMO_CODE_PATH,
		component: PromoCode,
	},
	{
		path: ADD_PROMO_CODE_PATH,
		component: AddPromoCode,
	},
	{
		path: MY_PROFILE_PATH,
		component: MyProfile,
	},








	{
		path: PRODUCT_ADD_PATH_2,
		component: AddProduct,
	},

	// Add more routes as needed
];
