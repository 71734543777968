import React, { useState } from "react";
import { InputFile } from "../../components/formelements/InputFile";
import { TextInput } from "../../components/formelements/TextInput";
import Button from "../../components/formelements/Button";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import Joi from "joi";
import { add_art_type, edit_art_type } from "../../utils/api/utilAPI";

const ArtTypeModal = ({ data, refreshFunc = () => { }, closeFunc = () => { } }) => {
  const [title, setTitle] = useState(data?.artTypeName || "");
  const [description, setDescription] = useState(data?.description || "");
  const [media, setMedia] = useState(data?.images || null);
  const [image, setImage] = useState(data?.images || [])
  const [previewImage, setPreviewImage] = useState(data?.images || "");
  const [errors, setErrors] = useState({});

  console.log(errors, "check for errors occurence in art type");
  console.log(media, "media");
  console.log(image, "check image")

  const schema = Joi.object({
    artTypeName: Joi.string().min(3).required().messages({
      "string.empty": "Art Type Name is required",
      "string.min": "Art Type Name must be at least 3 characters long",
    }),
    description: Joi.string().required().messages({
      "any.required": "Description is required",
    }),
    media: Joi.any()
      .custom((file, helpers) => {
        if (!file) {
          return helpers.message("Art type image is required");
        }
        if (!image.length > 0) {
          if (
            file.size > 2 * 1024 * 1024 ||
            !["image/jpeg", "image/png", "image/jpg"].includes(file.type)
          ) {
            return helpers.message(
              "Image must be in jpg/jpeg/png format and less than 2 MB"
            );
          }
        }
        return file;
      })
      .required()
      .messages({
        "any.required": "Art type image is required",
      }),
  });

  const validate = () => {
    const { error } = schema.validate({ artTypeName: title, description: description, media }, { abortEarly: false });

    if (!error) return null;

    const validationErrors = {};
    error.details.forEach((detail) => {
      validationErrors[detail.path[0]] = detail.message;
    });
    return validationErrors;
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file.type.split("/")[0] === "video" || file.type.split("/")[0] === "audio") {
      toast.error("Please select image only...")
      return
    }
    if (file) {
      const maxSize = 2 * 1024 * 1024;
      if (file.size <= maxSize) {
        setImage([])
        setMedia(file);
        setPreviewImage([URL.createObjectURL(file)]);
        setErrors({
          ...errors,
          media: "",
        });
        e.target.value = null
      } else {
        setMedia(null);
        setPreviewImage("");
        setErrors({
          ...errors,
          media: "Image must be less than 2MB",
        });
        e.target.value = null
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    formData.append("artTypeName", title);
    formData.append("description", description);
    if (media && !image.length > 0) {
      formData.append("media", media);
    }
    formData.append("images", JSON.stringify(image));

    try {
      if (data) {
        await edit_art_type(data?._id, formData);
        toast.success("Art type updated successfully", { toastId: "art type updated" });
      } else {
        formData.delete("images");
        await add_art_type(formData);
        toast.success("Art type added successfully", { toastId: "art type added" });
      }
      closeFunc()
      refreshFunc()

      // window.location.reload();
    } catch (error) {
      toast.error("Failed to submit art type", { toastId: "Failed adding or updating" });
    }
  };

  return (
    <div className="p-4">
      <TextInput
        label="Art type name"
        type="text"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
          setErrors({ ...errors, artTypeName: "" });
        }}
        error={errors.artTypeName || ""}
      />
      <label className="font-medium text-black text-base mb-2">Art type Description</label>
      <textarea
        className={`h-[172px] w-full rounded-[4px] border-[1px] border-[#ced4da] outline-none resize-none py-2 px-2 font-normal text-[#212529] 
          focus:border-[#86b7fe] focus:bg-white focus:outline-none focus:shadow-[0_0_0_0.25rem_rgba(13,110,253,.25)] focus:border-none`}
        name="description"
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
          setErrors({ ...errors, description: "" });
        }}
        maxLength={1000}
      />
      {errors?.description && (
        <span className="text-danger d-block">{errors?.description}</span>
      )}
      <div className="flex gap-5 mt-3">
        <InputFile
          accept="image/x-png,image/jpeg,image/jpg"
          type="file"
          label="Art type image"
          placeholder="Upload art type"
          subtext="Upload up to 2 MB"
          error={errors.media}
          onChange={handleFileSelect}
        />
        {previewImage && (
          <div
            style={{
              flexShrink: "0",
              position: "relative",
              width: "8rem",
              height: "8rem",
              marginRight: "2rem",
            }}
          >
            <img
              className="w-[inherit] h-[inherit]"
              src={previewImage}
              alt=""
            />
            <button
              style={{
                position: "absolute",
                top: "-5%",
                right: "-5%",
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => { setPreviewImage(""); setImage([]); setMedia(null) }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="28" cy="28" r="16" fill="white" />
                <path
                  d="M27.9994 4.66602C15.1427 4.66602 4.66602 15.1427 4.66602 27.9994C4.66602 40.856 15.1427 51.3327 27.9994 51.3327C40.856 51.3327 51.3327 40.856 51.3327 27.9994C51.3327 15.1427 40.856 4.66602 27.9994 4.66602ZM35.8393 33.366C36.516 34.0427 36.516 35.1627 35.8393 35.8393C35.4893 36.1894 35.046 36.3527 34.6027 36.3527C34.1594 36.3527 33.716 36.1894 33.366 35.8393L27.9994 30.4727L22.6327 35.8393C22.2827 36.1894 21.8393 36.3527 21.396 36.3527C20.9527 36.3527 20.5094 36.1894 20.1594 35.8393C19.4827 35.1627 19.4827 34.0427 20.1594 33.366L25.526 27.9994L20.1594 22.6327C19.4827 21.956 19.4827 20.836 20.1594 20.1594C20.836 19.4827 21.956 19.4827 22.6327 20.1594L27.9994 25.526L33.366 20.1594C34.0427 19.4827 35.1627 19.4827 35.8393 20.1594C36.516 20.836 36.516 21.956 35.8393 22.6327L30.4727 27.9994L35.8393 33.366Z"
                  fill="#E6355C"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
      <div style={{ display: "flex", gap: "16px" }}>
        <Button className="btn py-2 px-4" type="submit" onClick={handleSubmit}>
          {data ? "Save" : "Add"}
        </Button>
      </div>
    </div>
  );
};

ArtTypeModal.propTypes = {
  data: PropTypes.object,
};

export default ArtTypeModal;
