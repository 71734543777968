import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EVENT_PATH } from "../../utils/constants/routes";
import IconComponent from "../../components/icons/IconComponent";
import { Title } from "../../components/title/Title";
import styles from "./event.module.css";
import { STRINGS } from "./strings";
import { ICON_LABELS } from "../../components/icons";
import { TextInput } from "../../components/formelements/TextInput";
import { TextBox } from "../../components/formelements/TextBox";
import Button from "../../components/formelements/Button";
import { Paper } from "@mui/material";
import {
	DatePicker,
	LocalizationProvider,
	TimePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import moment from "moment";
import { AddEventValidations } from "../../utils/validations/joiValidations";
import { loadingActions } from "../../utils/redux/features/loadingSlice";
import { useDispatch } from "react-redux";
import { add_event } from "../../utils/api/utilAPI";
import { toast } from "react-toastify";

export const AddEvent = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		eventName: "",
		eventDate: moment(),
		eventStartDate: moment(),
		eventEndDate: moment(),
		eventArtistName: "",
		eventAddress: "",
		eventDetails: "",
	});
	const [validations, setValidations] = useState({
		eventName: "",
		eventArtistName: "",
		eventAddress: "",
		eventStartDate: "",
		eventEndDate: "",
		eventDetails: "",
		eventDate: "",
	});
	const handleFormDataChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};
	const getDateTimeInISO = (date, time) => {
		if (!date || !time) return null;
		const combined = moment(date).set({
			hour: moment(time).hour(),
			minute: moment(time).minute(),
		});
		return combined.toISOString();
	};

	const handleAddEvent = async (data) => {
		dispatch(loadingActions.loadingState(true));
		try {
			await add_event(data);
			toast.success("Event Added Successfully", {
				toastId: "Event Added Successfully",
			});
			navigate(EVENT_PATH);
		} catch (error) {
			toast.error(error?.response?.data?.message, {
				toastId: "Error  Adding Event",
			});
		} finally {
			dispatch(loadingActions.loadingState(false));
		}
	};

	const handleStartTimeChange = (newTime) => {
		setFormData({ ...formData, eventStartDate: newTime });
	};

	const handleEndTimeChange = (newTime) => {
		setFormData({ ...formData, eventEndDate: newTime });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = {
			eventName: formData.eventName,
			eventArtistName: formData.eventArtist,
			eventAddress: formData.eventAddress,
			eventStartDate: getDateTimeInISO(
				formData.eventDate,
				formData.eventStartDate
			),
			eventEndDate: getDateTimeInISO(formData.eventDate, formData.eventEndDate),
			eventDetails: formData.eventDetails,
			eventDate: formData.eventDate
				? moment(formData.eventDate).toISOString()
				: null,
		};
		const { error } = AddEventValidations.validate(data);
		if (error) {
			const validationErrors = error.details.reduce((acc, curr) => {
				acc[curr.path[0]] = curr.message;
				return acc;
			}, {});
			setValidations(validationErrors);
		} else {
			setValidations({});
			delete data.eventDate;
			handleAddEvent(data);
		}
	};

	console.log(validations, "check>>c")

	return (
		<div className="flex flex-col !h-full  justify-start gap-6  scrollPage">
			<Paper className="paperClass " component={Paper}>
				<div className="flex gap-20 border-bottom pb-3">
					<Link
						to={EVENT_PATH}
						className={styles.link}
						onClick={() => navigate(EVENT_PATH)}
					>
						<IconComponent iconLabel={ICON_LABELS.KEYBOARD_BACKSPACE} />
					</Link>
					<Title
						pagetitle={STRINGS.ADD_EVENT}
						fontsize="20px"
						fontWeight="600"
						color="#060709"
						border={false}
					/>
				</div>
				<TextInput
					type="text"
					name="eventName"
					label={STRINGS.EVENT_NAME}
					placeholder={STRINGS.EVENT_NAME}
					value={formData?.eventName}
					error={validations?.eventName}
					onChange={handleFormDataChange}
				/>
				<TextInput
					type="text"
					name="eventArtist"
					label={STRINGS.ARTIST_NAME}
					placeholder={STRINGS.ENTER_ARTIST_NAME}
					value={formData?.eventArtist}
					error={validations?.eventArtist}
					onChange={handleFormDataChange}
				/>
				<TextInput
					type="text"
					name="eventAddress"
					label={STRINGS.EVENT_COMPLETE_ADDRESS}
					placeholder={STRINGS.EVENT_COMPLETE_ADDRESS}
					value={formData?.eventAddress}
					error={validations?.eventAddress}
					onChange={handleFormDataChange}
				/>
				<div className="flex w-full justify-between gap-8">
					<div className="flex w-full gap-8">
						{/* Event Date */}
						<div className="flex w-1/2 flex-col gap-2">
							<label className="font-medium text-black text-base">Event Date</label>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DatePicker
									sx={{
										".MuiOutlinedInput-root": {
											height: "42px",
											borderRadius: "4px",
										},
										".MuiOutlinedInput-input": {
											fontFamily: "Poppins",
										},
									}}
									value={formData?.eventDate}
									onChange={(e) =>
										setFormData((formData) => ({
											...formData,
											eventDate: e,
										}))
									}
									disablePast
								/>
								{validations?.eventDate && (
									<span className="text-danger mb-2">
										{validations?.eventDate}
									</span>
								)}
							</LocalizationProvider>
						</div>

						{/* Event Time */}
						<div className="flex w-1/2 flex-col gap-2">
							<label className="font-medium text-black text-base">Event Time</label>
							<div className="flex gap-4">
								{/* Start Time */}
								<div className="w-1/2">
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<TimePicker
											value={formData.eventStartDate}
											onChange={handleStartTimeChange}
											sx={{
												".MuiOutlinedInput-root": {
													height: "42px",
													borderRadius: "4px",
												},
												".MuiOutlinedInput-input": {
													fontFamily: "Poppins",
												},
											}}
										/>
									</LocalizationProvider>
								</div>
								{/* End Time */}
								<div className="w-1/2">
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<TimePicker
											value={formData.eventEndDate}
											onChange={handleEndTimeChange}
											sx={{
												".MuiOutlinedInput-root": {
													height: "42px",
													borderRadius: "4px",
												},
												".MuiOutlinedInput-input": {
													fontFamily: "Poppins",
												},
											}}
										/>
									</LocalizationProvider>
								</div>
							</div>
							{validations?.eventEndDate && (
								<span className="text-danger mt-2">{validations?.eventEndDate}</span>
							)}
						</div>
					</div>


				</div>

				<TextBox
					name="eventDetails"
					label={STRINGS.EVENT_DETAILS}
					rows="5"
					placeholder={STRINGS.ENTER_EVENT_DETAILS}
					value={formData?.eventDetails}
					error={validations?.eventDetails}
					onChange={handleFormDataChange}
				/>
				<div className="flex justify-end gap-20 my-3">
					<Button variant="secondary" onClick={() => navigate(EVENT_PATH)}>
						{STRINGS.BTNTEXTCANCEL}
					</Button>
					<Button className="btn" type={"submit"} onClick={handleSubmit}>
						{STRINGS.BTNTEXTSUBMIT}
					</Button>
				</div>
			</Paper>
		</div>
	);
};
