import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { ARROW_LEFT, BACK_ARROW, DUMMY_COLLAB_PIC, FACEBOOK_ICON, INSTAGRAM_ICON, SOUNDCLOUD_ICON, SPOTIFY_ICON } from "../../utils/constants/assets";
import { get_collab_details } from "../../utils/api/utilAPI";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { post_collab } from "../../utils/api/utilAPI";
import { NA } from "../../utils/helperFuncs"
import { loadingActions } from "../../utils/redux/features/loadingSlice";
function CollaboratorDetails() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    const loading = useSelector((state) => state?.loadingReducer?.loading);
    const [collabratorDetails, setCollabratorDetails] = useState()
    useEffect(() => {
        dispatch(loadingActions.loadingState(true));
        get_collab_details(id).then((res) => {
            const { data } = res?.data
            setCollabratorDetails(data)
            dispatch(loadingActions.loadingState(false));
        }).catch((err) => {
            toast.error(err?.message);
            dispatch(loadingActions.loadingState(false));
        })
    }, [])
    const handleCollabRequest = async (status) => {
        try {
            const response = await post_collab(id, { accepted: status });
            toast.success(response?.data?.message, {
                toastId: " responding to collab request"
            })
            navigate("/collaborators")
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                toastId: "Error responding to collab request"
            })
        }
    }
    return (


        <div className="flex flex-col !h-full scrollPage justify-start gap-6">
            <Paper
                className="paperClass"
                component={Paper}
            > {loading ? "" :
                <>
                    <div className="flex justify-between">
                        <div className="flex gap-3 items-center">
                            <img src={BACK_ARROW} alt="backArrow" className="cursor-pointer h-6 w-6 " onClick={() =>
                                navigate("/collaborators")
                            } />
                            <label className="text-2xl font-bold">View Collaborator</label>
                        </div>
                        <div className="flex gap-2">
                            <button className="bg-[#FF2727] rounded-[160px] py-2 px-7 text-white" onClick={() => handleCollabRequest(false)}>Reject</button>
                            <button className="bg-[#41A41E] rounded-[160px] py-2 px-7 text-white" onClick={() => handleCollabRequest(true)}>Approve</button>
                        </div>
                    </div>
                    <hr className="w-full h-[1px] bg-[#E4E4E4] opacity-100" />
                    <label className='text-lg font-semibold text-[#E6355C]'>Basic Details</label>
                    <img src={collabratorDetails?.profilePicture} alt="collaboratorPic" className="h-40 w-40 object-cover" />
                    <div className="flex gap-x-96 mt-3">
                        <div className="flex flex-col gap-2">
                            <label className='text-xl font-bold text-[#000000]'>Full Name</label>
                            <p className="text-base font-medium text-[#858585]">{`${NA(collabratorDetails?.userDetails?.firstName)} ${NA(collabratorDetails?.userDetails?.lastName)}`}</p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <label className='text-xl font-bold text-[#000000]'>Email Address</label>
                            <p className="text-base font-medium text-[#858585]">{NA(collabratorDetails?.userDetails?.email)}</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 mt-3">
                        <label className='text-xl font-bold text-[#000000]'>Phone Number</label>
                        <p className="text-base font-medium text-[#858585]">+1{NA(collabratorDetails?.mobileNumber)}</p>
                    </div>
                    <div className='flex flex-col gap-2 mt-3'>
                        <label className='text-lg font-semibold text-[#E6355C]'>Tell about yourself</label>
                        <label className='text-lg font-bold'>Describe your style of music.</label>
                        <p className='text-lg font-normal text-[#858585]'>{NA(collabratorDetails?.collabDetails?.description)}</p>
                    </div>
                    <div className='flex flex-col gap-2 mt-6'>
                        <label className='text-lg font-bold'>Tell us about your inspiration in making music. What inspires you?</label>
                        <p className='text-lg font-normal text-[#858585]'>{NA(collabratorDetails?.collabDetails?.tellUsAboutInspirationFroMusic)}</p>
                    </div>
                    <div className='flex flex-col gap-2 mt-6'>
                        <label className='text-lg font-bold'>What is your favorite Aeternal Lovers design or track. Why?</label>
                        <p className='text-lg font-normal text-[#858585]'>{NA(collabratorDetails?.collabDetails?.favouriteAbtAeternal)}</p>
                    </div>
                    <div className='flex flex-col gap-2 mt-6'>
                        <label className='text-lg font-bold'>We would be delighted to hear about anything else you would like to share with us.</label>
                        <p className='text-lg font-normal text-[#858585]'>{NA(collabratorDetails?.collabDetails?.additionalDescription)}</p>
                    </div>
                    <div className="flex gap-2">
                        {collabratorDetails?.collabDetails?.socialMedia?.Instagram && <div className="h-auto md:w-[20%] bg-[#FFF1F4] rounded-xl p-3 flex gap-3 !border border-[#FF8EC4] cursor-pointer"
                            onClick={() => {
                                let instagramUrl = collabratorDetails.collabDetails.socialMedia.Instagram;
                                if (!/^https?:\/\//i.test(instagramUrl)) {
                                    instagramUrl = `https://${instagramUrl}`;
                                }
                                window.open(instagramUrl, '_blank', 'noopener noreferrer');
                            }}
                        >
                            <img src={INSTAGRAM_ICON} alt='INSTAGRAM_ICON' />
                            <div className="flex flex-col items-start">
                                <label className='text-base font-semibold'>Instagram</label>
                            </div>
                            <div className="flex justify-end w-full items-center">
                                <img src={ARROW_LEFT} alt="ARROW_LEFT" className="h-6" />
                            </div>
                        </div>}
                        {collabratorDetails?.collabDetails?.socialMedia?.facebook && <div className="h-auto md:w-[20%] bg-[#F1FAFF] rounded-xl p-3 flex gap-3 !border border-[#73B0FF] cursor-pointer"
                            onClick={() => {
                                let facebookUrl = collabratorDetails.collabDetails.socialMedia.facebook;
                                if (!/^https?:\/\//i.test(facebookUrl)) {
                                    facebookUrl = `https://${facebookUrl}`;
                                }
                                window.open(facebookUrl, '_blank', 'noopener noreferrer');
                            }}
                        >
                            <img src={FACEBOOK_ICON} alt='INSTAGRAM_ICON' />
                            <div className="flex flex-col items-start">
                                <label className='text-base font-semibold'>Facebook</label>
                            </div>
                            <div className="flex justify-end w-full items-center">
                                <img src={ARROW_LEFT} alt="ARROW_LEFT" className="h-6" />
                            </div>
                        </div>}
                        {collabratorDetails?.collabDetails?.socialMedia?.spotify && <div className="h-auto md:w-[20%] bg-[#EFFFF5] rounded-xl p-3 flex gap-3 !border border-[#0FA845] cursor-pointer"
                            onClick={() => {
                                let spotifyUrl = collabratorDetails.collabDetails.socialMedia.spotify;
                                if (!/^https?:\/\//i.test(spotifyUrl)) {
                                    spotifyUrl = `https://${spotifyUrl}`;
                                }
                                window.open(spotifyUrl, '_blank', 'noopener noreferrer');
                            }}
                        >
                            <img src={SPOTIFY_ICON} alt='INSTAGRAM_ICON' />
                            <div className="flex flex-col items-start">
                                <label className='text-base font-semibold'>Spotify</label>
                            </div>
                            <div className="flex justify-end w-full items-center">
                                <img src={ARROW_LEFT} alt="ARROW_LEFT" className="h-6" />
                            </div>
                        </div>}
                        {collabratorDetails?.collabDetails?.socialMedia?.soundCloud && <div className="h-auto md:w-[20%] bg-[#FFE8E0] rounded-xl p-3 flex gap-3 !border border-[#FF5500] cursor-pointer"
                            onClick={() => {
                                let soundCloudUrl = collabratorDetails.collabDetails.socialMedia.soundCloud;
                                if (!/^https?:\/\//i.test(soundCloudUrl)) {
                                    soundCloudUrl = `https://${soundCloudUrl}`;
                                }
                                window.open(soundCloudUrl, '_blank', 'noopener noreferrer');
                            }}
                        >
                            <img src={SOUNDCLOUD_ICON} alt='INSTAGRAM_ICON' />
                            <div className="flex flex-col items-start">
                                <label className='text-base font-semibold text-nowrap'>Sound Cloud</label>
                            </div>
                            <div className="flex justify-end w-full items-center">
                                <img src={ARROW_LEFT} alt="ARROW_LEFT" className="h-6" />
                            </div>
                        </div>}
                    </div></>}
            </Paper>
        </div>

    )
}

export default CollaboratorDetails
