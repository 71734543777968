import Joi from 'joi';

export const searchUsersValidations = Joi.object({
  email: Joi.string()
    .trim() 
    .email({ tlds: { allow: false } }) 
    .required()
    .custom((value, helpers) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        return helpers.message('Please enter a valid email address');
      }

      return value;
    })
    .messages({
      'string.empty': 'Email cannot be blank',
      'string.email': 'Please enter a valid email address'
    })
});


export const localMediaValidation = Joi.object({
  title: Joi.string().max(100).required().messages({
    'string.empty': 'Mandatory field',
    'string.max': 'Title cannot exceed 100 characters',
    'any.required': 'Title is required'
  }),

  description: Joi.string().max(200).required().messages({
    'string.empty': 'Mandatory field',
    'string.max': 'Description too long',
    'any.required': 'Description is required'
  }),

  audioFile: Joi.any().custom((file, helpers) => {
    if (!file) {
      return helpers.message('Please upload an audio file.');
    }
  
    if (!file.type.includes("audio/")) {
      return helpers.message('Please upload an audio file.');
    }
  
    return file;
  }),

  thumbnailImage: Joi.any().custom((file, helpers) => {
    if (!file) {
      return helpers.message('Mandatory field');
    }
  
    if (!file.type.includes("image/")) {
      return helpers.message('Please upload a img file');
    }
  
    return file;
  }),

  videoFile: Joi.any().custom((file, helpers) => {
    if (!file) {
      return helpers.message('Please upload a video file');
    }
  
    if (!file.type.includes("video/")) {
      return helpers.message('Please upload a video file');
    }

    if (file.size > 838860800) {
      return helpers.message('File size must be less than 800 MB');
    }
  
    return file;
  }),
});

export const audioMediaValidation = Joi.object({
  audioFile: Joi.any().custom((file, helpers) => {
    if (!file) {
      return helpers.message('Please upload an audio file.');
    }
  
    if (!file.type.includes("audio/")) {
      return helpers.message('Please upload an audio file.');
    }
  
    return file;
  }),
});

export const thumbnailMediaValidation = Joi.object({
  thumbnailImage: Joi.any().custom((file, helpers) => {
    if (!file) {
      return helpers.message('Mandatory field');
    }
  
    if (!file.type.includes("image/")) {
      return helpers.message('Please upload a img file');
    }
  
    return file;
  })
});

export const videoMediaValidation = Joi.object({
  videoFile: Joi.any().custom((file, helpers) => {
    if (!file) {
      return helpers.message('Please upload a video file');
    }
  
    if (!file.type.includes("video/")) {
      return helpers.message('Please upload a video file');
    }

    if (file.size > 838860800) {
      return helpers.message('File size must be less than 800 MB');
    }
  
    return file;
  }),
});

export const youtubeValidation = Joi.object({
  title: Joi.string().max(100).required().messages({
    'string.empty': 'Mandatory field',
    'string.max': 'Title cannot exceed 100 characters',
    'any.required': 'Title is required'
  }),

  description: Joi.string().max(200).required().messages({
    'string.empty': 'Mandatory field',
    'string.max': 'Description too long',
    'any.required': 'Description is required'
  }),

  youtubeUrl: Joi.string()
  .required() 
  .custom((value, helpers) => {
    const url = !/^https?:\/\//i.test(value) ? "https://" + value : value;

    try {
      const parsedUrl = new URL(url);
      const hostWithoutWWW = parsedUrl.host.replace(/^www\./, "");
      const allowedHosts = ["youtube.com", "youtu.be", "m.youtube.com"];

      if (!allowedHosts.includes(hostWithoutWWW)) {
        return helpers.error('any.invalid');
      }

      for (const host of allowedHosts) {
        const hostRegex = new RegExp(host, "g");
        const occurrences = (url.match(hostRegex) || []).length;
        if (occurrences > 1) {
          return helpers.error('any.invalid');
        }
      }

      let videoId = null;
      if (hostWithoutWWW === "youtube.com" || hostWithoutWWW === "m.youtube.com") {
        if (parsedUrl.pathname === "/watch") {
          videoId = parsedUrl.searchParams.get("v");
        } else if (parsedUrl.pathname.startsWith("/playlist")) {
          return helpers.error('any.invalid');
        }
      } else if (hostWithoutWWW === "youtu.be") {
        videoId = parsedUrl.pathname.slice(1);
      }

      if (videoId && videoId.length === 11) {
        return value;
      } else {
        return helpers.error('any.invalid');
      }
    } catch (error) {
      return helpers.error('any.invalid');
    }
  })
  .messages({
    'string.empty': 'YouTube URL is required',
    'any.invalid': 'Please enter a valid YouTube URL'
  })
});
