import React, { useEffect, useState } from "react";
import { Modal, Fade, TextField, IconButton, Avatar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Button from "../../../components/formelements/Button";
import PropTypes from "prop-types";
import { update_admin_profile } from "../../../utils/api/utilAPI";
import { toast } from "react-toastify";
import { ParaGraph } from "../../../components/title/ParaGraph";
import styles from "./adminprofile.module.css";
import IconComponent from "../../../components/icons/IconComponent";
import { ICON_LABELS } from "../../../components/icons";
import { InputFile } from "../../../components/formelements/InputFile";
import Joi from 'joi';


const EditProfilePopup = ({ modal, handleClose, prevData, refreshFunc = () => { } }) => {
  const [profileImage, setProfileImage] = useState(null);
  const [prevProfileImg, setPrevProfileImg] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setPrevProfileImg(prevData?.profilePicture)
    setFirstName(prevData?.firstName);
    setLastName(prevData?.lastName);
    setErrors("")
    return () => {
      setProfileImage(null)
    }
  }, [prevData, modal]);


  const schema = Joi.object({
    firstName: Joi.string().min(2).max(50).required().label("First Name"),
    lastName: Joi.string().min(2).max(50).required().label("Last Name"),
  });

  const handleSave = async () => {

    const { error } = schema.validate({ firstName, lastName }, { abortEarly: false });
    if (error) {
      const errorMessages = error.details.reduce((acc, item) => {
        acc[item.path[0]] = item.message;
        return acc;
      }, {});
      setErrors(errorMessages);
      return;
    }

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    if (profileImage) {
      formData.append("profileImage", profileImage);
    }
    try {
      await update_admin_profile(formData);
      toast.success("Profile updated successfully");
      handleClose();
      refreshFunc();
    } catch (error) {
      toast.error(error.response?.data?.message || "Profile update failed");
    }
  };

  const handleFileChange = (e) => {
    setProfileImage(e.target.files[0]);
    e.target.value = null
    console.log("File change triggered");
  };

  return (
    <Modal
      open={modal}
      onClose={handleClose}
      closeAfterTransition
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Fade in={modal}>
        <div className="bg-white w-[550px] shadow-lg">
          <div className="bg-[#E6355C] px-4 py-2 flex justify-between items-center">
            <div>
              <ParaGraph
                text="Edit your Profile"
                fontsize="18px"
                fontWeight="600"
                color="#FFFFFF"
              />
              <ParaGraph
                text="Here you can change your profile details"
                fontsize="14px"
                fontWeight="400"
                color="#FFFFFF"
              />
            </div>
            <IconComponent
              iconLabel={ICON_LABELS.CANCEL}
              color="#ffffff"
              fontSize="24px"
              onClick={handleClose}
              className="cursor-pointer"
            />
          </div>
          <div className="flex flex-col gap-4 py-2 px-4 mt-2">
            <div className="avatar class">
              <Avatar
                alt="Admin Profile Image"
                src={profileImage ? URL.createObjectURL(profileImage) : prevProfileImg}
                sx={{ width: 115, height: 115, marginBottom: '8px' }}
              />
              <InputFile
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                placeholder="Upload Image"
                subtext="Click to upload profile image"
                marginbot="unset"
              />
            </div>
            <div>
              <label className="mb-2 text-black font-medium">First Name</label>
              <TextField
                type="text"
                variant="outlined"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className={styles.customTextField}
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
            </div>
            <div>
              <label className="mb-2 text-black font-medium">Last Name</label>
              <TextField
                type="text"
                variant="outlined"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className={styles.customTextField}
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
            </div>
          </div>
          <div className="p-4">
            <Button
              className="btn py-2 px-4"
              type="button"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

EditProfilePopup.propTypes = {
  modal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  prevData: PropTypes.object,
  refreshFunc: PropTypes.func,
};

export default EditProfilePopup;
