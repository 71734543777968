import React, {
	useEffect,
	useState,
	useRef,
	useCallback,
	createContext,
} from "react";
import { Title } from "../../components/title/Title";
import { Paper } from "@mui/material";
import RowsSelector from "../../components/pagination/RowSelector";
import PageSelector from "../../components/pagination/PageSelector";
import { loadingActions } from "../../utils/redux/features/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import DynamicTabs from "../../components/tabs/DynamicTabs";
import MusicManagementTable from "./MusicManagementTable";
import { get_music_list, get_music_request } from "../../utils/api/utilAPI";
import { toast } from "react-toastify";
import Player from "./Player";
import Button from "../../components/formelements/Button";
import UploadMusicModal from "./Video/UploadMusicModal";
const tabLabels = [
	{
		label: "All Music",
		value: "all",
	},
	{
		label: "Music Reviews",
		value: "musicReview",
	},
];

export const PlayerContext = createContext(null);

export const MusicManagement = () => {
	const dispatch = useDispatch();
	const [tableData, setTableData] = useState([]);
	const [selectedTab, setSelectedTab] = useState(tabLabels[0]);
	const loading = useSelector((state) => state?.state?.loadingReducer?.loading);
	const [action, setAction] = useState(false);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [totalCount, setTotalCount] = useState(0);

	// Player
	const [open, setOpen] = useState(false);
	const [selectMedia, setSelectMedia] = useState(null);
	const [loadMedia, setLoadMedia] = useState(null);
	const [timeProgress, setTimeProgress] = useState(0);
	const [duration, setDuration] = useState(0);

	// primary controls
	const [play, setPlay] = useState(false);
	const [prev, setPrev] = useState(false);
	const [next, setNext] = useState(false);
	const [repeat, setRepeat] = useState(false);
	const [shuffle, setShuffle] = useState(false);
	const [showVideo, setShowVideo] = useState(false);

	const [playerEngine, setPlayerEngine] = useState(null);

	const [render, setRender] = useState(false);

	const [ytState, setYTState] = useState(-1);

	const [uploadModal, setUploadModal] = useState(false);

	const [currentTrack, setCurrentTrack] = useState(null);

	const [buffering, setBuffering] = useState(false);

	const ytref = useRef(null);
	const videoRef = useRef(null);
	const miniVidRef = useRef(null);

	const audioRef = useRef();
	const progressBarRef = useRef();
	const vidProgressBarRef = useRef();
	const playAnimationRef = useRef();

	useEffect(() => {
		if (playerEngine) {
			if (
				playerEngine?.index < 0 ||
				playerEngine?.index >= playerEngine?.arr.length
			) {
				setCurrentTrack(null);
				return;
			}

			// destruction of unused refs
			videoRef.current = null;
			audioRef.current = null;
			ytref.current = null;
			miniVidRef.current = null;

			setCurrentTrack(playerEngine?.arr[playerEngine?.index]);

			if (playerEngine.stdi - 1 >= 0) setPrev(true);
			else setPrev(false);
			if (playerEngine.stdi + 1 < playerEngine.totalCount) setNext(true);
			else setNext(false);

			if (!open) setOpen(true);
		}
	}, [playerEngine]);

	const reOrgAll = (details) => {
		console.log({ details }, "reOrgAll");
		let tempArr = details?.data?.map((item, index) => {
			const tempObj = {
				title: item?.title,
				artist: item?.artist,
				playTime: item?.playTime,
				isYoutube: item?.isYoutube,
				isVideoOnly: item?.isVideoOnly,
			};
			if (item?.isYoutube) {
				tempObj["youtube"] = {
					url: item?.youtubeUrl,
					thumbnail: item?.thumbnailImages["youtubeThumbnail"],
				};
			} else {
				tempObj["audio"] = {
					src: item?.musicUrl,
					thumbnail: item?.thumbnailImages["musicThumbnail"],
				};
				if ("videoUrl" in item && item?.videoUrl !== "") {
					tempObj["video"] = {
						src: item?.videoUrl,
						thumbnail: item?.thumbnailImages["videoThumbnail"],
					};
				}
			}
			console.log({ tempObj });
			return tempObj;
		});

		// console.log("TEMP ARR: ", tempArr);

		return {
			arr: tempArr,
			totalCount: details?.totalCount || tempArr.length,
			totalPages: Math.ceil(details?.totalCount / limit),
		};
	};

	const reOrgRequest = (details) => {
		console.log({ details }, "reOrgRequest");
		let tempArr = details?.data?.map((item, index) => {
			const tempObj = {
				title: item?.music?.title,
				artist: item?.artist,
				playTime: item?.music?.playTime,
				isYoutube: item?.music?.isYoutube,
				isVideoOnly: item?.music?.isVideoOnly,
			};
			if (item?.music?.isYoutube) {
				tempObj["youtube"] = {
					url: item?.music?.youtubeUrl,
					thumbnail: item?.music?.thumbnailImages["youtubeThumbnail"],
				};
			} else {
				tempObj["audio"] = {
					src: item?.music?.musicUrl,
					thumbnail: item?.music?.thumbnailImages["musicThumbnail"],
				};
				if ("videoUrl" in item?.music && item?.music?.videoUrl !== "") {
					tempObj["video"] = {
						src: item?.music?.videoUrl,
						thumbnail: item?.music?.thumbnailImages["videoThumbnail"],
					};
				}
			}
			console.log({ tempObj });
			return tempObj;
		});

		// console.log("TEMP ARR: ", tempArr);

		return {
			arr: tempArr,
			totalCount: details?.totalCount,
			totalPages: Math.ceil(details?.totalCount / limit),
		};
	};

	const repeatAni = useCallback(() => {
		// console.log("repeatAni: ", { videoRef, audioRef, ytref });

		// console.log(".");

		const currentTime = !currentTrack?.isYoutube
			? videoRef.current
				? videoRef.current?.getCurrentTime()
				: audioRef.current?.currentTime || 0
			: ytref.current?.getCurrentTime() || 0;

		setTimeProgress(currentTime);
		const progressPercent = (currentTime / duration) * 100;

		// console.log("currentTime: ", currentTime);

		if (progressBarRef && progressBarRef.current) {
			progressBarRef.current.value = currentTime;
			progressBarRef.current.style.setProperty(
				"--range-progress",
				`${progressPercent}%`
			);
		}
		if (vidProgressBarRef && vidProgressBarRef.current) {
			vidProgressBarRef.current.value = currentTime;
			vidProgressBarRef.current.style.setProperty(
				"--range-progress",
				`${progressPercent}%`
			);
		}

		playAnimationRef.current = requestAnimationFrame(repeatAni);
	}, [duration, currentTrack]);

	const shuffleFunc = () => {
		const i = Math.round(Math.random() * (playerEngine.totalCount - 1));

		musicEngine(playerEngine.source, i);
	};

	const playerCleanup = () => {
		setLoadMedia(true); // will set false in onLoadedMetadata
		setShowVideo(false);
		setPlay(false);
		if (playAnimationRef.current) {
			cancelAnimationFrame(playAnimationRef.current);
		}
		setTimeProgress(0);
		if (progressBarRef.current) {
			progressBarRef.current.value = 0;
			progressBarRef.current.max = 0;
			progressBarRef.current.style.setProperty("--range-progress", "0%");
		}
		if (vidProgressBarRef.current) {
			vidProgressBarRef.current.value = 0;
			vidProgressBarRef.current.max = 0;
			vidProgressBarRef.current.style.setProperty("--range-progress", "0%");
		}
		setDuration(0); // why did i never place this here before...
		// setCurrentTrack(null);
	};

	// const onLoadedMetadata = (durationVal) => {
	// 	const seconds = !currentTrack?.isYoutube
	// 		? audioRef.current.duration
	// 		: durationVal;
	// 	setDuration(seconds);
	// 	progressBarRef.current.max = seconds;
	// 	if (!currentTrack?.isYoutube) setPlay(true);
	// 	setLoadMedia(false);
	// 	if (currentTrack?.isYoutube) setShowVideo(true);
	// };
	const onLoadedMetadata = (durationVal) => {
		const seconds = durationVal;
		setDuration(seconds);
		progressBarRef.current.max = seconds;
		if (currentTrack?.isVideoOnly === true)
			vidProgressBarRef.current.max = seconds;
		if (!currentTrack?.isYoutube) setPlay(true);
		if (currentTrack?.isYoutube || currentTrack?.isVideoOnly)
			setShowVideo(true);
		setLoadMedia(false);
	};

	// const musicEngine = async (s, stdi) => {
	// 	// console.log("STDI: ", stdi);

	// 	// if (playerEngine && stdi === playerEngine.stdi) return;

	// 	// const convertedPage = Math.floor(stdi / 10) + 1;
	// 	// const convertedIndex = stdi % 10;
	// 	// playerCleanup();

	// 	// console.log("STDI: ", stdi);

	// 	// console.log("music engine: ", s, q, stdi);
	// 	playerCleanup();
	// 	// if (playerEngine && stdi === playerEngine.stdi) return; // very weird...
	// 	if (s === playerEngine?.source && stdi === playerEngine?.stdi) {
	// 		// console.log("ENTERED SAME SONG");
	// 		if (playerEngine?.arr[playerEngine?.index]?.isYoutube) {
	// 			ytref.current.pauseVideo();
	// 			ytref.current?.seekTo(0);
	// 			ytref.current?.playVideo();
	// 			onLoadedMetadata(ytref.current.getDuration());
	// 		} else {
	// 			// console.log("REPLAYING AUDIO: ", audioRef);
	// 			audioRef.current.pause();
	// 			audioRef.current.currentTime = 0;
	// 			audioRef.current.play();
	// 			onLoadedMetadata();
	// 		}
	// 	}

	// 	const convertedPage = s === "search" ? 1 : Math.floor(stdi / 10) + 1;
	// 	const convertedIndex = s === "search" ? stdi : stdi % 10;

	// 	if (
	// 		playerEngine &&
	// 		s === playerEngine.source &&
	// 		10 * (playerEngine.page - 1) <= stdi &&
	// 		stdi < 10 * playerEngine.page
	// 	) {
	// 		// no fetch calls needed
	// 		// console.log("NO FETCH ME");
	// 		setPlayerEngine((curr) => ({
	// 			...curr,
	// 			page: convertedPage,
	// 			stdi,
	// 			index: convertedIndex,
	// 		}));
	// 	} else {
	// 		// assuming valid index
	// 		// console.log("FETCH ME");
	// 		if (s === "all") {
	// 			get_music_list(convertedPage, 10).then((res) => {
	// 				const reorganized = reOrgAll(res?.data);
	// 				setPlayerEngine({
	// 					...reorganized,
	// 					page: convertedPage,
	// 					limit: 10,
	// 					source: s,
	// 					stdi,
	// 					index: convertedIndex,
	// 				});
	// 			});
	// 		} else if (s === "request") {
	// 			get_music_request(convertedPage, 10).then((res) => {
	// 				const reorganized = reOrgRequest(res?.data);
	// 				setPlayerEngine({
	// 					...reorganized,
	// 					page: convertedPage,
	// 					limit: 10,
	// 					source: s,
	// 					stdi,
	// 					index: convertedIndex,
	// 				});
	// 			});
	// 		}
	// 	}
	// };

	const musicEngine = (s, stdi) => {
		// console.log("STDI: ", stdi);

		console.log("music engine: ", s, stdi);
		playerCleanup();

		if (s === playerEngine?.source && stdi === playerEngine?.stdi) {
			// have to explicitly call requestAnimationFrame in case of same song
			if (playerEngine?.arr[playerEngine?.index]?.isYoutube) {
				ytref.current?.seekTo(0);
				onLoadedMetadata(ytref.current.getDuration());
			} else {
				if (playerEngine?.arr[playerEngine?.index]?.isVideoOnly) {
					videoRef.current.seekTo(0, "seconds");
					videoRef.current.getInternalPlayer().play();
					onLoadedMetadata(videoRef.current.getDuration()); // see if this works...
				} else {
					audioRef.current.pause();
					audioRef.current.currentTime = 0;
					audioRef.current.play();
					playAnimationRef.current = requestAnimationFrame(repeatAni);
					onLoadedMetadata(audioRef.current.duration);
				}
			}
		} else {
			const convertedPage = s === "search" ? 1 : Math.floor(stdi / 10) + 1;
			const convertedIndex = s === "search" ? stdi : stdi % 10;

			if (
				playerEngine &&
				s === playerEngine.source &&
				10 * (playerEngine.page - 1) <= stdi &&
				stdi < 10 * playerEngine.page
			) {
				// no fetch calls needed
				setPlayerEngine((curr) => ({
					...curr,
					page: convertedPage,
					stdi,
					index: convertedIndex,
				}));
			} else {
				// assuming valid index
				if (s === "all") {
					get_music_list(convertedPage, 10).then((res) => {
						const reorganized = reOrgAll(res?.data);
						setPlayerEngine({
							...reorganized,
							page: convertedPage,
							limit: 10,
							source: s,
							stdi,
							index: convertedIndex,
						});
					});
				} else if (s === "request") {
					get_music_request(convertedPage, 10).then((res) => {
						const reorganized = reOrgRequest(res?.data);
						setPlayerEngine({
							...reorganized,
							page: convertedPage,
							limit: 10,
							source: s,
							stdi,
							index: convertedIndex,
						});
					});
				}
			}
		}
	};

	const handleBack = () => {
		if (playerEngine.stdi - 1 >= 0)
			musicEngine(playerEngine.source, playerEngine.stdi - 1);
		else musicEngine(playerEngine.source, 0);
	};

	const handleForward = () => {
		// console.log("HANDLE FORWARD: ", playerEngine);
		if (playerEngine.stdi + 1 < playerEngine.totalCount)
			musicEngine(playerEngine.source, playerEngine.stdi + 1);
		else {
			musicEngine(playerEngine.source, 0);
			// onLoadedMetadata(duration);
			// setLoadMedia(false);
		}
	};

	const handleTabSelect = (activeTab) => {
		setSelectedTab(activeTab);
	};
	const handlePaginationLimitChange = (event) => {
		setPage(1);
		setLimit(event.target.value);
	};

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};
	const fetchMusicRequest = async () => {
		dispatch(loadingActions.loadingState(true));
		try {
			const response = await get_music_request(page, limit);
			setTableData(response?.data?.data);
			setTotalCount(response?.data?.totalCount);
		} catch (error) {
			toast.error(error?.response?.data?.message, {
				toastId: "Error fetching music  list",
			});
		} finally {
			dispatch(loadingActions.loadingState(false));
		}
	};
	const fetchMusicList = async () => {
		dispatch(loadingActions.loadingState(true));
		try {
			const response = await get_music_list(page, limit);
			setTableData(response?.data?.data);
			setTotalCount(response?.data?.totalCount);
		} catch (error) {
			toast.error(error?.response?.data?.message, {
				toastId: "Error fetching music  list",
			});
		} finally {
			dispatch(loadingActions.loadingState(false));
		}
	};

	const handleMusicClose = () => {
		setUploadModal(false);
	};

	useEffect(() => {
		setTableData([]);
		if (selectedTab?.value === "all") {
			fetchMusicList();
		}
		if (selectedTab?.value === "musicReview") {
			fetchMusicRequest();
		}
	}, [page, limit, selectedTab]);
	useEffect(() => {
		if (selectedTab?.value === "all" && action) {
			fetchMusicList();
		}
		if (selectedTab?.value === "musicReview" && action) {
			fetchMusicRequest();
		}
		setAction(false);
	}, [action]);

	return (
		<PlayerContext.Provider
			value={{
				play,
				setPlay,
				open,
				setOpen,
				currentTrack,
				setCurrentTrack,
				musicEngine,
				playerEngine,
				//-------
				// open,
				// setOpen,
				selectMedia,
				setSelectMedia,
				loadMedia,
				setLoadMedia,
				timeProgress,
				setTimeProgress,
				duration,
				setDuration,
				repeat,
				setRepeat,
				shuffle,
				setShuffle,
				showVideo,
				setShowVideo,
				audioRef,
				ytState,
				setYTState,
				progressBarRef,
				vidProgressBarRef,
				playAnimationRef,
				handleBack,
				handleForward,
				prev,
				next,
				shuffleFunc,
				ytref,
				playerCleanup,
				onLoadedMetadata,
				videoRef,
				miniVidRef,
				repeatAni,
				buffering,
				setBuffering,
			}}
		>
			<div className={`flex flex-col justify-start `}>
				<Paper
					className="paperClassMusic"
					component={Paper}
					sx={{
						"& .MuiPaper-root": {
							height: [open ? "calc(100% - 4.75rem)" : "100%"],
							marginBottom: [open ? "0" : "8px"],
						},
					}}
				>
					<div className="flex flex-row items-center justify-between  ">
						<Title
							pagetitle={"Music Management"}
							fontsize="20px"
							fontWeight="600"
							color="#060709"
							border={false}
						/>

						<Button
							className="btn"
							type="button"
							disabled={false}
							onClick={() => {
								setUploadModal(true);
							}}
						>
							Upload Music
						</Button>
					</div>

					<DynamicTabs tabLabels={tabLabels} onTabSelect={handleTabSelect} />

					<MusicManagementTable
						tableData={tableData}
						loadingData={loading}
						filterType={selectedTab}
						sendAction={(e) => setAction(e)}
						page={page}
						limit={limit}
						musicEngine={musicEngine}
					/>
					<div className="flex justify-between mt-auto items-center">
						<RowsSelector
							limit={limit}
							handlePaginationLimitChange={handlePaginationLimitChange}
						/>
						<PageSelector
							totalCount={totalCount}
							limit={limit}
							page={page}
							handlePageChange={handlePageChange}
						/>
					</div>
				</Paper>
				{open && <Player />}
				{/* {open && (
					<div className="bg-white w-full h-[4.75rem] absolute bottom-0 left-0" />
				)} */}
			</div>
			<UploadMusicModal
				modal={uploadModal}
				handleClose={handleMusicClose}
				refreshFunc={fetchMusicList}
			/>
		</PlayerContext.Provider>
	);
};
