import React, { useContext, useState } from "react";
import Paper from "@mui/material/Paper";
import {
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import PropTypes from "prop-types";
import { Title } from "../../components/title/Title";
import {
	renderTableCell,
	renderTableHeading,
	renderTextContent,
	formatDateTime,
	formatTimePlayer,
} from "../../utils/helperFuncs";
import { ICON_LABELS } from "../../components/icons";
import IconComponent from "../../components/icons/IconComponent";
import DeletePopup from "../../components/formelements/DeletePopup";
import { accept_music_request, delete_music } from "../../utils/api/utilAPI";
import { toast } from "react-toastify";
import ViewButton from "../../components/formelements/ViewButton";
import { PlayerContext } from "./MusicManagement";

const tableHeading = [
	"S. No.",
	"Music Title",
	"Play Time",
	"Artist/Creator",
	"Upload Date and Time",
	"Video",
	"Action",
];

export default function MusicManagementTable({
	tableData,
	loadingData,
	filterType,
	sendAction,
	page,
	limit,
	musicEngine,
}) {
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [musicId, setMusicId] = useState("");

	const { open } = useContext(PlayerContext);

	const renderMediaThumbnail = (row) => {
		let url;
		const image = filterType?.value === "all" ? row : row?.music;
		if (image?.thumbnailImages?.videoThumbnail.length !== 0) {
			url = image?.thumbnailImages?.videoThumbnail;
		} else {
			url = image?.thumbnailImages?.youtubeThumbnail;
		}

		return url ? (
			<button
				type="button"
				className={`flex w-[63px] h-[44px] bg-[#D9D9D9] rounded-[6px] relative `}
			>
				<img
					src={url}
					alt=""
					className="object-fill w-full h-full rounded-[6px]"
				/>
			</button>
		) : (
			renderTextContent("N/A")
		);
	};
	const renderActionButtons = (row, index) => {
		return filterType?.value !== "all" ? (
			<div className="flex justify-start p-auto gap-2">
				<ViewButton
					className={`bg-[#E6355C] text-white py-2 px-2 rounded-[6px] flex gap-1 items-center`}
					type="button"
					icon={ICON_LABELS.PLAY_BTN}
					onClick={() => {
						// console.log("index", index, "stdi: ", index + (page - 1) * limit);
						musicEngine("request", index + (page - 1) * limit);
					}}
				>
					Play
				</ViewButton>

				<button
					type="button"
					className={`w-[44px] h-[44px] bg-[#E4FFDD] rounded-[5.02px] px-[8px] py-[10px]`}
					onClick={() => handleMusicRequest(row?._id, row?.music?._id, 1)}
				>
					<IconComponent
						iconLabel={ICON_LABELS.CHECK_CIRCLE}
						color="#167D4C"
						fontSize="24px"
					/>
				</button>
				<button
					type="button"
					className="w-[44px] h-[44px] bg-[#FFDDDD] rounded-[5.02px] px-[8px] py-[10px]"
					onClick={() => handleMusicRequest(row?._id, row?.music?._id, 0)}
				>
					<IconComponent
						iconLabel={ICON_LABELS.CANCEL}
						color="#E63535"
						fontSize="24px"
					/>
				</button>
			</div>
		) : (
			<div className="flex justify-start p-auto gap-2">
				<ViewButton
					className={`bg-[#E6355C] text-white py-2 px-2 rounded-[6px] flex gap-1 items-center`}
					type="button"
					icon={ICON_LABELS.PLAY_BTN}
					onClick={() => {
						musicEngine("all", index + (page - 1) * limit);
					}}
				>
					Play
				</ViewButton>
				<button
					type="button"
					className="w-[44px] h-[44px] bg-[#FFDDDD] rounded-[5.02px] px-[8px] py-[10px]"
					onClick={() => handleDeleteModal(row?._id)}
				>
					<IconComponent
						iconLabel={ICON_LABELS.DELETE}
						color="#E63535"
						fontSize="24px"
					/>
				</button>
			</div>
		);
	};
	const handleDeleteModal = (id) => {
		setMusicId(id);
		setDeleteModalOpen(true);
	};
	const handleDelete = async () => {
		try {
			await delete_music(musicId);
			setDeleteModalOpen(false);
			toast.success("Music deleted successfully", {
				toastId: "User Deleted",
			});
			sendAction(true);
		} catch (error) {
			toast.error(error.response?.data?.message, {
				toastId: "Error deleting user",
			});
			setDeleteModalOpen(false);
		}
	};

	const handleClose = () => {
		setDeleteModalOpen(false);
	};
	const handleMusicRequest = async (reqId, musicId, isAccepted) => {
		try {
			const response = await accept_music_request({
				reqId,
				musicId,
				isAccepted,
			});
			toast.success(response?.data?.message, {
				toastId: " responding to music request",
			});
			sendAction(true);
		} catch (error) {
			toast.error(error?.response?.data?.message, {
				toastId: "Error responding to music request",
			});
		}
	};

	return (
		<Paper
			className={`randomClass1 ${
				open && "playerTableHeight"
			}  flex-grow flex flex-col justify-start pt-3 gap-[24px] shadow-none !rounded-none border-t-[1px]`}
			component={Paper}
		>
			<div className="flex justify-between items-center flex-wrap">
				<Title
					pagetitle={filterType?.label}
					fontsize="20px"
					fontWeight="600"
					color="#060709"
					border={false}
				/>
			</div>
			{loadingData ? (
				""
			) : (
				<TableContainer className="scrollContainer w-auto overflow-auto">
					{tableData.length !== 0 ? (
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow className="h-11 p-3">
									{tableHeading.map((heading) => renderTableHeading(heading))}
								</TableRow>
							</TableHead>
							<TableBody>
								{tableData?.map((row, index) => (
									<TableRow
										key={row?._id}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										{renderTableCell(<p>{index + 1}</p>)}
										{renderTableCell(
											filterType?.value === "all"
												? row?.title
												: row?.music?.title,
											{ maxWidth: "200px", wordBreak: "break-word" }
										)}
										{renderTableCell(
											filterType?.value === "all"
												? formatTimePlayer(row?.playTime)
												: formatTimePlayer(row?.music?.playTime) ||
														renderTextContent("N/A")
										)}
										{renderTableCell(
											row?.artist?.firstName || row?.artist?.lastName
												? `${row?.artist?.firstName} ${row?.artist?.lastName}`
												: renderTextContent("Deleted User")
										)}
										{renderTableCell(
											formatDateTime(row?.createdAt) || renderTextContent("N/A")
										)}
										{renderTableCell(renderMediaThumbnail(row))}
										{renderTableCell(renderActionButtons(row, index))}
									</TableRow>
								))}
							</TableBody>
						</Table>
					) : (
						<div className="flex flex-col gap-10 justify-center items-center mt-[150px] ">
							<IconComponent
								iconLabel={ICON_LABELS.NO_DATA_FOUND}
								color="#EFEFEF"
								fontSize="80px"
							/>
							<p className="text-[18px] font-[600] text-[#888888] opacity-[0.4]">
								No data found
							</p>{" "}
						</div>
					)}
				</TableContainer>
			)}
			<DeletePopup
				modal={deleteModalOpen}
				handleDone={handleDelete}
				handleClose={handleClose}
			/>
		</Paper>
	);
}

MusicManagementTable.propTypes = {
	filterType: PropTypes.object,
	tableData: PropTypes.array,
	loadingData: PropTypes.bool,
	sendAction: PropTypes.func,
};
