import React, { useState, useRef, useEffect } from 'react';
import styles from "../../Player.module.css";
import { CircularProgress, Tab, Tabs, TextField } from '@mui/material';
import { ParaGraph } from '../../../../components/title/ParaGraph';
import Button from '../../../../components/formelements/Button';
import UploadIcon from '../../../../components/icons/SvgIcons';
import { audioMediaValidation, localMediaValidation, thumbnailMediaValidation, videoMediaValidation, youtubeValidation } from './ValidationsUploadMusic';
import { extractYouTubeVideoId, generateVideoThumbnail, getMediaDuration, getYouTubeVideoDuration, parseYouTubeDuration } from '../../../../utils/helperFuncs';
import { finaliseMultipart, initialiseMultipart } from '../../../../utils/api/utilAPI';
import { toast } from 'react-toastify';


const MusicUpload = ({ artistId, collabId, handleClose }) => {
    
    // Input States
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    // Tab State
    const [tabValue, setTabValue] = useState(0);

    // File upload states - local media
    const [audioFile, setAudioFile] = useState(null);
    const [thumbnailImage, setThumbnailImage] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [videoThumbnailImg, setVideoThumbnailImage] = useState(null)

    // Youtube url
    const [youtubeUrl, setYoutubeUrl] = useState('');

    // Playtime states
    const [audioDuration, setAudioDuration] = useState(null);
    const [videoDuration, setVideoDuration] = useState(null);
    const [chunksUploaded, setChunksUploaded] = useState(0);

    // File input refs
    const musicFileInputRef = useRef(null);
    const thumbnailFileInputRef = useRef(null);
    const videoFileInputRef = useRef(null);

    // dragging states 
    const [draggingAudio, setDraggingAudio] = useState(false);
    const [draggingImg, setDraggingImg] = useState(false);
    const [draggingVid, setDraggingVid] = useState(false);

    // others
    const [errors, setErrors] = useState({});
    const [uploading, setUploading] = useState(false);


    // Handlers
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleFileClick = (inputRef) => inputRef.current.click();

    const handleDragOver = (e, setDragging) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (e, setDragging) => {
        e.preventDefault();
        setDragging(false);
    };

    const handleDrop = (e, setDragging, setFile, setDuration, type) => {
        e.preventDefault();
        setDragging(false);
        const file = e.dataTransfer.files[0];
        if (file) {
            handleFileChangeOnDrop(file, setFile, setDuration, type);
        }
    };

    const handleValidation = (formData, validationSchema) => {
        const { error } = validationSchema.validate(formData, { abortEarly: false });
        if (error) {
            const errorMessages = {};
            error.details.forEach(err => {
                errorMessages[err.path[0]] = err.message;
            });
            return { isValid: false, errors: errorMessages };
        } else {
            return { isValid: true, errors: {} };
        }
    };

    const handleFileChange = (e, setFile, setDuration, type = "thumbnail") => {
        const file = e.target.files[0];
        let isValid, errors;

        setErrors({})

        if (type === "audio") {
            ({ isValid, errors } = handleValidation({ audioFile: file }, audioMediaValidation));
        } else if (type === "thumbnail") {
            ({ isValid, errors } = handleValidation({ thumbnailImage: file }, thumbnailMediaValidation));
        } else if (type === "video") {
            ({ isValid, errors } = handleValidation({ videoFile: file }, videoMediaValidation));
        }

        if (!isValid) {
            setErrors(errors);
            return;
        }

        setFile(file);

        if (file && setDuration) {
            getMediaDuration(file, setDuration);
            if (file.type.includes('video')) {
                generateVideoThumbnail(file, setVideoThumbnailImage);
            }
        }
    };

    const handleFileChangeOnDrop = (file, setFile, setDuration, type) => {
        let isValid, errors;
        setErrors({});

        if (type === "audio") {
            ({ isValid, errors } = handleValidation({ audioFile: file }, audioMediaValidation));
        } else if (type === "video") {
            ({ isValid, errors } = handleValidation({ videoFile: file }, videoMediaValidation));
        } else if (type === "thumbnail") {
            ({ isValid, errors } = handleValidation({ thumbnailImage: file }, thumbnailMediaValidation));
        }

        if (!isValid) {
            setErrors(errors);
            return;
        }

        setFile(file);
        if (file && setDuration) {
            getMediaDuration(file, setDuration);
            if (file.type.includes('video')) {
                generateVideoThumbnail(file, setVideoThumbnailImage);
            }
        }
    };


    const handleYouTubeInput = (e) => {
        setYoutubeUrl(e.target.value)
    }

    async function uploadPart(fileChunk, presignedUrl, partNo) {
        const uploadResponse = await fetch(presignedUrl, {
            method: "PUT",
            body: fileChunk,
        });
        setChunksUploaded((curr) => curr + 1);
        return {
            ETag: uploadResponse.headers.get("ETag") || "",
            PartNumber: partNo,
        };
    }

    const handleSave = async () => {
        let formData = new FormData();
        let validationSchema = tabValue === 0 ? localMediaValidation : youtubeValidation;
        let isValid, errors;

        if (tabValue === 0) {

            if (!audioFile && !videoFile) {
                ({ isValid, errors } = handleValidation({ title, description, thumbnailImage, audioFile, videoFile }, validationSchema));
            } else {
                ({ isValid, errors } = handleValidation({ title, description, thumbnailImage }, validationSchema));
            }


            if (!isValid) {
                setErrors(errors);
                return;
            }

            formData.append("artistId", artistId ? artistId : collabId)
            formData.append("title", title);
            formData.append("description", description);
            formData.append("isYoutube", false);

            if (thumbnailImage) formData.append("musicThumbnail", thumbnailImage);
            if (videoFile) formData.append("video", videoFile);
            if (audioFile) formData.append("music", audioFile);
            if (videoFile) formData.append("name", videoFile.name);
            if (videoFile) formData.append("videoThumbnail", videoThumbnailImg)

            formData.append("isVideoExist", videoFile ? true : false);
            formData.append("isVideoOnly", !audioFile);
            formData.append("playTime", audioFile ? audioDuration : videoDuration);


        } else if (tabValue === 1) {

            ({ isValid, errors } = handleValidation({ title, description, youtubeUrl }, validationSchema));

            if (!isValid) {
                setErrors(errors);
                return;
            }
            // YouTube payload
            formData.append("artistId", artistId ? artistId : collabId)
            formData.append("title", title);
            formData.append("description", description);
            formData.append("isYoutube", true);
            formData.append("youtubeUrl", youtubeUrl);

            if (youtubeUrl) {
                const tempID = extractYouTubeVideoId(youtubeUrl);
                formData.append("youtubeId", tempID);
                try {
                    const playTemp = await getYouTubeVideoDuration(tempID);
                    formData.append("playTime", parseYouTubeDuration(playTemp));
                } catch (error) {
                    setErrors((prevStates) => ({
                        ...prevStates,
                        youtubeUrl: "YouTube video of this ID does not exist."
                    }));
                    return;
                }
            }
            formData.append("youtubeThumbnail", null);
        }

        setErrors({});
        setUploading(true);

        try {
            if (tabValue === 0) {
                // Local media upload logic
                const renamedFile = videoFile
                    ? new File([videoFile], videoFile.name.replace(/\s+/g, ""), { type: videoFile.type })
                    : null;

                let parts = 0, chunkSize = 0, vidSize = 0;
                if (renamedFile) {
                    vidSize = renamedFile.size;
                    parts = Math.min(Math.ceil(renamedFile.size / 8000000), 20);
                    chunkSize = renamedFile.size / parts;
                }

                let intialized = null;

                if (renamedFile && renamedFile.size > 10485760) {
                    intialized = await initialiseMultipart({
                        name: renamedFile.name,
                        parts,
                        videoSize: vidSize,
                    });
                }

                if (renamedFile && intialized) {
                    const uploadsArray = [];
                    for (let i = 0; i < parts; i++) {
                        const start = i * chunkSize;
                        const end = Math.min(start + chunkSize, renamedFile.size);
                        const blob = renamedFile.slice(start, end);
                        uploadsArray.push(uploadPart(blob, intialized.data.parts[i].signedUrl, i + 1));
                    }

                    let uploadResponses = await Promise.all(uploadsArray);
                    formData.append("fileId", intialized.data.fileId);
                    formData.append("fileKey", intialized.data.fileKey);
                    formData.append("parts", JSON.stringify(uploadResponses));
                }

                await finaliseMultipart(formData);
            } else if (tabValue === 1) {
                // YouTube specific finalization
                await finaliseMultipart(formData);
                toast.success("Uploaded music successfully", {
                    toastId: "Upload music successfully",
                });
            }
        } catch (error) {
            toast.error(error.response?.data?.error || "Unknown error occurred", {
                toastId: "Error Uploading Music Files"
            });
        } finally {
            setUploading(false)
            handleClose();
            window.location.reload();
        }
    };


    return (
        <div className="flex flex-col gap-4 py-2 px-4 mt-2">
            {/* Title Input */}
            <div>
                <label className="mb-2 text-black font-medium">Title</label>
                <TextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter the title of this track"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className={styles.customTextField}
                    error={!!errors.title}
                    helperText={errors.title}
                />
            </div>

            {/* Description Input */}
            <div>
                <label className="mb-2 text-black font-medium">Description*</label>
                <TextField
                    type="text"
                    variant="outlined"
                    placeholder="Type here"
                    fullWidth
                    multiline
                    minRows={3}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className={styles.customTextField}
                    error={!!errors.description}
                    helperText={errors.description}
                />
            </div>

            {/* Tabs */}
            <div className="flex justify-center">
                <Tabs
                    TabIndicatorProps={{ hidden: true }}
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="upload tabs"
                    className={styles.tabWrapper}
                >
                    <Tab label="Local Media" className={tabValue === 0 ? styles.activeTab : ''} />
                    <Tab label="YouTube" className={tabValue === 1 ? styles.activeTab : ''} />
                </Tabs>
            </div>

            {/* Local Media Tab Content */}
            {tabValue === 0 && (
                <div>
                    <ParaGraph
                        text="Please upload a music file, a video file, or both."
                        fontWeight="500"
                        fontsize="16px"
                        color="black"
                    />
                    <div className="flex gap-x-2 mt-2">
                        {/* Music File Upload */}
                        <div className='w-[100%]'>
                            <div className={`${styles.fileUploaderBox} ${draggingAudio && styles.dragging}`}
                                onDragOver={(e) => handleDragOver(e, setDraggingAudio)}
                                onDragLeave={(e) => handleDragLeave(e, setDraggingAudio)}
                                onDrop={(e) => handleDrop(e, setDraggingAudio, setAudioFile, setAudioDuration, "audio")}
                                onClick={() => handleFileClick(musicFileInputRef)}
                            >
                                <input
                                    type="file"
                                    accept=".mp3, .wav"
                                    ref={musicFileInputRef}
                                    style={{ display: "none" }}
                                    onChange={(e) => handleFileChange(e, setAudioFile, setAudioDuration, "audio")}
                                />
                                <div className="h-full w-full flex flex-col justify-center items-center">
                                    <UploadIcon />
                                    <ParaGraph
                                        text={audioFile ? `${audioFile?.name} | ${audioDuration}` : draggingAudio ? "Drop Here" : "Drag or upload your music"}
                                        fontWeight="500"
                                        fontsize="12px"
                                        color="inherit"
                                    />
                                </div>
                            </div>
                            {/* error message */}
                            <ParaGraph
                                text={errors.audioFile}
                                fontWeight="500"
                                fontsize="12px"
                                color="#d32f2f"
                            />
                        </div>

                        {/* Thumbnail Image Upload */}
                        <div className='w-[100%]'>
                            <div className={`${styles.fileUploaderBox} ${draggingImg && styles.dragging}`}
                                onDragOver={(e) => handleDragOver(e, setDraggingImg)}
                                onDragLeave={(e) => handleDragLeave(e, setDraggingImg)}
                                onDrop={(e) => handleDrop(e, setDraggingImg, setThumbnailImage, "thumbnail")}
                                onClick={() => handleFileClick(thumbnailFileInputRef)}>
                                <input
                                    type="file"
                                    accept=".jpg, .jpeg, .png"
                                    ref={thumbnailFileInputRef}
                                    style={{ display: "none" }}
                                    onChange={(e) => handleFileChange(e, setThumbnailImage)}
                                />
                                <div className="h-full w-full flex flex-col justify-center items-center">
                                    {thumbnailImage ? (
                                        <div className='flex flex-col items-center'>
                                            <img
                                                src={URL.createObjectURL(thumbnailImage)}
                                                alt="Thumbnail Preview"
                                                className="max-w-full max-h-[8rem] object-contain"
                                            />
                                            <ParaGraph
                                                text={thumbnailImage?.name}
                                                fontWeight="500"
                                                fontsize="12px"
                                                color="inherit"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <UploadIcon />
                                            <ParaGraph
                                                text={draggingImg ? "Drop Here" : "Drag or upload your Thumbnail image"}
                                                fontWeight="500"
                                                fontsize="12px"
                                                color="inherit"
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            {/* error message */}
                            <ParaGraph
                                text={errors.thumbnailImage}
                                fontWeight="500"
                                fontsize="12px"
                                color="#d32f2f"
                            />
                        </div>
                    </div>

                    {/* Video File Upload */}
                    <div>
                        <div className={`${styles.fileUploaderBox} ${draggingVid && styles.dragging} mt-3`}
                            onDragOver={(e) => handleDragOver(e, setDraggingVid)}
                            onDragLeave={(e) => handleDragLeave(e, setDraggingVid)}
                            onDrop={(e) => handleDrop(e, setDraggingVid, setVideoFile, setVideoDuration, "video")}
                            onClick={() => handleFileClick(videoFileInputRef)}
                        >
                            <input
                                type="file"
                                accept="video/*"
                                ref={videoFileInputRef}
                                style={{ display: "none" }}
                                onChange={(e) => handleFileChange(e, setVideoFile, setVideoDuration, "video")}
                            />
                            <div className="h-full w-full flex flex-col justify-center items-center">
                                {videoThumbnailImg ? (
                                    <div className='flex flex-col items-center'>
                                        <img
                                            src={URL.createObjectURL(videoThumbnailImg)}
                                            alt="Thumbnail Preview"
                                            className="max-w-full max-h-[8rem] object-contain"
                                        />
                                        <ParaGraph
                                            text={`${videoFile?.name} | ${videoDuration}`}
                                            fontWeight="500"
                                            fontsize="12px"
                                            color="inherit"
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <UploadIcon />
                                        <ParaGraph
                                            text={videoFile ? `${videoFile?.name} | ${videoDuration}` : draggingVid ? "Drop Here" : "Drag or upload your video"}
                                            fontWeight="500"
                                            fontsize="12px"
                                            color="inherit"
                                        />
                                    </>
                                )}

                            </div>
                        </div>
                        {/* error message */}
                        <ParaGraph
                            text={errors.videoFile}
                            fontWeight="500"
                            fontsize="12px"
                            color="#d32f2f"
                        />
                    </div>
                </div>
            )}

            {/* YouTube Tab Content */}
            {tabValue === 1 && (
                <div>
                    <label className="mb-2 text-black font-medium">YouTube URL</label>
                    <TextField
                        type="text"
                        variant="outlined"
                        placeholder="Enter YouTube URL"
                        fullWidth
                        className={styles.customTextField}
                        onChange={handleYouTubeInput}
                        error={!!errors.youtubeUrl}
                        helperText={errors.youtubeUrl}
                    />
                </div>
            )}

            {/* Submit Button */}
            <Button className="btn w-[fit-content] flex items-center gap-1.5" type="button" onClick={handleSave}>
                Upload Music
                {uploading && (
                    <CircularProgress size={20} className={styles.buttonLoader} />
                )}
            </Button>
        </div>
    );
};

export default MusicUpload;
