import React, { useState } from "react";
import { STRINGS } from "./strings";
import { InputFile } from "../../components/formelements/InputFile";
import { TextInput } from "../../components/formelements/TextInput";
import Button from "../../components/formelements/Button";
import CustomPhoneInput from "../../components/formelements/CustomPhoneInput";
import { Title } from "../../components/title/Title";
import { AddCollaboratorValidations } from "../../utils/validations/joiValidations";
import { add_collaborator } from "../../utils/api/utilAPI";
import { toast } from "react-toastify";
import { Paper } from "@mui/material";
import PropTypes from "prop-types";
import { BACK_ARROW, FACEBOOK_ICON, GALLERY_ICON, INSTAGRAM_ICON, SOUNDCLOUD_ICON, SPOTIFY_ICON } from "../../utils/constants/assets";
import { useNavigate } from "react-router-dom";
import { Select, MenuItem } from "@mui/material";
import { formDataConverter } from "../../utils/helperFuncs";
const AddCollaborators = ({ handleModalOpen, onCollabIdReceived, isCollabReg, setActiveStep, prevEmail }) => {
  const [formData, setFormData] = useState({
    profilePicture: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    description: "",
    collabType: "",
    tellUsAboutInspirationFroMusic: "",
    favouriteAbtAeternal: "",
    additionalDescription: "",
    facebook: "",
    Instagram: "",
    spotify: "",
    soundCloud: ""
  })
  const [validations, setValidations] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    profilePicture: "",
    collabType: ""
  });
  const navigate = useNavigate()
  const [image, setImage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneChange = (value, data) => {
    setFormData({
      ...formData,
      mobileNumber: value,
      country_code: data?.dialCode,
    });
  };

  const handleProfileImage = (e) => {
    if (e.target.files[0]) {
      const maxSize = 200 * 1024;
      const file = e.target.files[0];
      if (file.size <= maxSize) {
        setImage(URL.createObjectURL(file));
        setFormData({ ...formData, "profilePicture": file })
        setValidations({
          ...validations,
          profilePicture: "",
        });
        e.target.value = null
      } else {
        setImage("");
        setValidations({
          ...validations,
          profilePicture: "Profile Picture must be less than 200kb",
        });
        e.target.value = null
        return;
      }
    }
  };

  const handleFileDelete = () => {
    setImage("");
    setValidations({
      ...validations,
      profilePicture: "",
    });
  };

  const handleAddCollab = async () => {
    if (isCollabReg) {
      setActiveStep(2);
    } else {
      const data = formDataConverter(formData)
      try {
        const response = await add_collaborator(data);
        const collabId = response?.data?.data?.id
        toast.success(response?.data?.message, {
          toastId: "added collaborator successfully",
        });
        navigate("/collaborators")
        onCollabIdReceived(collabId);
        handleModalOpen(false);
      } catch (error) {
        toast.error(error.response?.data?.message, {
          toastId: "add collaborator failed",
        });
      }
    }
  };

  const handleAddButton = (e) => {
    e.preventDefault();
    const { error } = AddCollaboratorValidations.validate({
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email || prevEmail,
      mobileNumber: formData.mobileNumber,
      collabType: formData.collabType,
      Instagram: formData.Instagram,
      facebook: formData.facebook,
      spotify: formData.spotify,
      soundCloud: formData.soundCloud
    });
    if (error) {
      const validationErrors = error.details.reduce((acc, curr) => {
        acc[curr.path[0]] = curr.message;
        return acc;
      }, {});
      setValidations(validationErrors);
    }
    else if (!image) {
      toast.error("Please Select Profile Picture")
      return
    }
    else {
      setValidations({});
      handleAddCollab();
    }
  };
  return (
    // <div className="px-4">
    //   <div className="flex gap-4 w-full">
    //     <div className="w-1/2">
    //       <TextInput
    //         type="text"
    //         label={STRINGS.FIRST_NAME}
    //         placeholder={STRINGS.FIRST_NAME}
    //         name="firstName"
    //         value={formData.firstName}
    //         onChange={handleInputChange}
    //         error={validations?.firstName}
    //       />
    //     </div>
    //     <div className="w-1/2">
    //       <TextInput
    //         type="text"
    //         label={STRINGS.LAST_NAME}
    //         placeholder={STRINGS.LAST_NAME}
    //         name="lastName"
    //         value={formData.lastName}
    //         onChange={handleInputChange}
    //         error={validations?.lastName}
    //       />
    //     </div>
    //   </div>

    //   <TextInput
    //     className="w-1/2"
    //     type="text"
    //     label={STRINGS.EMAIL}
    //     placeholder={STRINGS.EMAIL}
    //     name="email"
    //     value={`${prevEmail ? prevEmail : formData.email}`}
    //     onChange={handleInputChange}
    //     error={validations?.email}
    //   />
    //   <div className="flex flex-col gap-2 mb-3">
    //     <Title
    //       pagetitle={"Phone Number"}
    //       fontsize="16px"
    //       fontWeight="500"
    //       color="#060709"
    //       border={false}
    //     />
    //     <CustomPhoneInput
    //       value={formData.phoneNumber}
    //       disabled={false}
    //       onChange={handlePhoneChange}
    //       error={validations.phoneNumber}
    //     />
    //   </div>
    //   <div className="flex flex-col gap-2 mb-3">
    //     <Title
    //       pagetitle={"Profile Image"}
    //       fontsize="16px"
    //       fontWeight="500"
    //       color="#060709"
    //       border={false}
    //     />
    //     <div className="flex gap-5">
    //       <InputFile
    //         accept="image/x-png,image/jpeg,image/jpg"
    //         type="file"
    //         placeholder={"Upload Profile Image"}
    //         subtext={"Image must be less than 200kb"}
    //         error={validations?.profileImage}
    //         onChange={handleProfileImage}
    //       />
    //       {image && <div
    //         style={{
    //           flexShrink: "0",
    //           position: "relative",
    //           width: "8rem",
    //           height: "8rem",
    //           marginRight: "2rem",
    //         }}
    //       >
    //         <img
    //           className="w-[inherit] h-[inherit]"
    //           src={image}
    //           alt=""
    //         />
    //         <button
    //           style={{
    //             position: "absolute",
    //             top: "-5%",
    //             right: "-5%",
    //             width: "1.5rem",
    //             height: "1.5rem",
    //             display: "flex",
    //             cursor: "pointer",
    //           }}
    //           onClick={handleFileDelete}
    //         >
    //           <svg
    //             width="24"
    //             height="24"
    //             viewBox="0 0 56 56"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <circle cx="28" cy="28" r="16" fill="white" />
    //             <path
    //               d="M27.9994 4.66602C15.1427 4.66602 4.66602 15.1427 4.66602 27.9994C4.66602 40.856 15.1427 51.3327 27.9994 51.3327C40.856 51.3327 51.3327 40.856 51.3327 27.9994C51.3327 15.1427 40.856 4.66602 27.9994 4.66602ZM35.8393 33.366C36.516 34.0427 36.516 35.1627 35.8393 35.8393C35.4893 36.1894 35.046 36.3527 34.6027 36.3527C34.1594 36.3527 33.716 36.1894 33.366 35.8393L27.9994 30.4727L22.6327 35.8393C22.2827 36.1894 21.8393 36.3527 21.396 36.3527C20.9527 36.3527 20.5094 36.1894 20.1594 35.8393C19.4827 35.1627 19.4827 34.0427 20.1594 33.366L25.526 27.9994L20.1594 22.6327C19.4827 21.956 19.4827 20.836 20.1594 20.1594C20.836 19.4827 21.956 19.4827 22.6327 20.1594L27.9994 25.526L33.366 20.1594C34.0427 19.4827 35.1627 19.4827 35.8393 20.1594C36.516 20.836 36.516 21.956 35.8393 22.6327L30.4727 27.9994L35.8393 33.366Z"
    //               fill="#E6355C"
    //             />
    //           </svg>
    //         </button>
    //       </div>}
    //     </div>

    //   </div>
    //   <div className="flex justify-start gap-4 my-3">
    //     <Button style={{ border: "0" }} type="submit" onClick={handleAddButton}>
    //       {isCollabReg ? "Upload Music" : "Submit"}
    //     </Button>
    //   </div>
    // </div>
    <div className="!h-full scrollPage">
      <Paper className="paperClass" component={Paper}>
        <div className="flex flex-col gap-6 p-3 ">
          <div className="flex gap-3">
            <img src={BACK_ARROW} alt="backArrow" className="cursor-pointer" onClick={() =>
              navigate("/collaborators")
            } />
            <label className="text-2xl font-bold">Add Collaborator</label>
          </div>
          <hr className="w-full h-[1px] bg-[#E4E4E4] opacity-100" />
          <label className='text-lg font-semibold text-[#E6355C]'>Basic Details</label>
          {!image ? <><input type='file' style={{ display: "none" }} id='uploadImage' onChange={handleProfileImage} />
            <label htmlFor='uploadImage' className='w-[90%] md:w-[35%] lg:w-[25%]'>
              <div type='button' className='!border rounded-lg text-sm !border-dashed bg-[#EAEFFC] p-3 border-[#6964B4] text-[#6964B4] md:text-base font-normal flex gap-1 cursor-pointer 2xl:text-lg'>
                <img src={GALLERY_ICON} alt='galleryIcon' />
                Upload your Images</div>
            </label>
            {validations?.profilePicture && <span className="text-danger d-block">{validations?.profilePicture}</span>}
          </>
            : <div
              style={{
                flexShrink: "0",
                position: "relative",
                width: "8rem",
                height: "8rem",
                marginRight: "2rem",
              }}
            >
              <img
                className="w-[inherit] h-[inherit]"
                src={image}
                alt=""
              />
              <button
                style={{
                  position: "absolute",
                  top: "-5%",
                  right: "-5%",
                  width: "1.5rem",
                  height: "1.5rem",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={handleFileDelete}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="28" cy="28" r="16" fill="white" />
                  <path
                    d="M27.9994 4.66602C15.1427 4.66602 4.66602 15.1427 4.66602 27.9994C4.66602 40.856 15.1427 51.3327 27.9994 51.3327C40.856 51.3327 51.3327 40.856 51.3327 27.9994C51.3327 15.1427 40.856 4.66602 27.9994 4.66602ZM35.8393 33.366C36.516 34.0427 36.516 35.1627 35.8393 35.8393C35.4893 36.1894 35.046 36.3527 34.6027 36.3527C34.1594 36.3527 33.716 36.1894 33.366 35.8393L27.9994 30.4727L22.6327 35.8393C22.2827 36.1894 21.8393 36.3527 21.396 36.3527C20.9527 36.3527 20.5094 36.1894 20.1594 35.8393C19.4827 35.1627 19.4827 34.0427 20.1594 33.366L25.526 27.9994L20.1594 22.6327C19.4827 21.956 19.4827 20.836 20.1594 20.1594C20.836 19.4827 21.956 19.4827 22.6327 20.1594L27.9994 25.526L33.366 20.1594C34.0427 19.4827 35.1627 19.4827 35.8393 20.1594C36.516 20.836 36.516 21.956 35.8393 22.6327L30.4727 27.9994L35.8393 33.366Z"
                    fill="#E6355C"
                  />
                </svg>
              </button>
            </div>}
        </div>
        <form className='flex flex-col gap-3 p-3' >
          <div className='flex flex-col gap-2 md:flex-row'>
            <div className='md:w-[50%]'>
              <TextInput
                type="text"
                label="First Name"
                placeholder="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                error={validations?.firstName}
              />
            </div>
            <div className='md:w-[50%]'>
              <TextInput
                type="text"
                label="Last Name"
                placeholder="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                error={validations?.lastName}
              />
            </div>
          </div>
          <TextInput
            type="email"
            label="Email Address"
            placeholder="Enter Email address"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            error={validations?.email}
          />    <div className="flex flex-col gap-2 mb-3">
            <Title
              pagetitle={"Phone Number"}
              fontsize="16px"
              fontWeight="500"
              color="#060709"
              border={false}
            />
            <CustomPhoneInput
              value={formData.mobileNumber}
              disabled={false}
              onChange={handlePhoneChange}
              error={validations.mobileNumber}
            />
          </div>
          <label className="font-medium text-black text-base">Collab Type</label>
          <Select
            id="demo-select-small"
            name='collabType'
            className='!outline-none !border-[#E4E4E4]'
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              "& .MuiOutlinedInput-input": {
                paddingY: ".7rem !important"
              },
              borderRadius: "5px",
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #CACACA"
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
                borderRadius: "4px",
                outline: 0,
                boxShadow: "0 0 0 .25rem rgba(13, 110, 253, .25)"
              },
            }}
            onChange={handleInputChange}
            displayEmpty
            renderValue={(value) => value || "Select Collab Type"}
          >
            <MenuItem value="general">General</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
          {validations?.collabType && (
            <p className="text-red-400 mt-2 font-normal text-sm">{validations?.collabType}</p>
          )}
          <label className='text-lg font-semibold text-[#E6355C]'>Tell about yourself</label>
          <label className="font-medium text-black text-base">Describe your style of music.</label>
          <textarea
            className={`h-[172px] w-full rounded-[4px] border-[1px] border-[#ced4da] outline-none resize-none py-2 px-2 font-normal text-[#212529] 
              focus:border-[#86b7fe] focus:bg-white focus:outline-none focus:shadow-[0_0_0_0.25rem_rgba(13,110,253,.25)] focus:border-none`}
            name="description"
            placeholder="Type Here...."
            onChange={handleInputChange}
            value={formData?.description}
            maxLength={1000}
          />
          <label className="font-medium text-black text-base">Tell us about your inspiration in making music. What inspires you?</label>
          <textarea
            className={`h-[172px] w-full rounded-[4px] border-[1px] border-[#ced4da] outline-none resize-none py-2 px-2 font-normal text-[#212529] 
                      focus:border-[#86b7fe] focus:bg-white focus:outline-none focus:shadow-[0_0_0_0.25rem_rgba(13,110,253,.25)] focus:border-none`}
            name="tellUsAboutInspirationFroMusic"
            placeholder="Type Here...."
            value={formData?.tellUsAboutInspirationFroMusic}
            onChange={handleInputChange}
            maxLength={1000}
          />
          <label className="font-medium text-black text-base">What is your favorite Aeternal Lovers design or track. Why?</label>
          <textarea
            className={`h-[172px] w-full rounded-[4px] border-[1px] border-[#ced4da] outline-none resize-none py-2 px-2 font-normal text-[#212529] 
                      focus:border-[#86b7fe] focus:bg-white focus:outline-none focus:shadow-[0_0_0_0.25rem_rgba(13,110,253,.25)] focus:border-none`}
            name="favouriteAbtAeternal"
            placeholder="Type Here...."
            value={formData?.favouriteAbtAeternal}
            onChange={handleInputChange}
            maxLength={1000}
          />
          <label className="font-medium text-black text-base">We would be delighted to hear about anything else you would like to share with us.</label>
          <textarea
            className={`h-[172px] w-full rounded-[4px] border-[1px] border-[#ced4da] outline-none resize-none py-2 px-2 font-normal text-[#212529] 
                      focus:border-[#86b7fe] focus:bg-white focus:outline-none focus:shadow-[0_0_0_0.25rem_rgba(13,110,253,.25)] focus:border-none`}
            name="additionalDescription"
            placeholder="Type Here...."
            value={formData?.additionalDescription}
            onChange={handleInputChange}
            maxLength={1000}
          />
          <label className="font-medium text-black text-base">Connect your social media accounts so that people can learn more about your work.</label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 mr-2">
              <img src={INSTAGRAM_ICON} alt='instagramIcon' />
            </div>
            <input
              name="Instagram"
              placeholder="www.Instagram.com"
              onChange={handleInputChange}
              value={formData?.Instagram}
              className="w-full py-[16px] pl-12 pr-[8px] rounded-[8px] border !border-[#E6355C] focus:!border-[#E6355C] focus:outline-none"
            />
          </div>
          {validations?.Instagram && (
            <p className="text-red-400 mt-2 font-normal text-sm">{validations?.Instagram}</p>
          )}
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 mr-2">
              <img src={FACEBOOK_ICON} alt='instagramIcon' />
            </div>
            <input
              name="facebook"
              placeholder="www.Facebook.Com"
              value={formData?.facebook}
              onChange={handleInputChange}
              className=" w-full py-[16px] pl-12 pr-[8px] rounded-[8px] border !border-[#1877F2] focus:!border-[#1877F2] focus:outline-none"
            />
          </div>
          {validations?.facebook && (
            <p className="text-red-400 mt-2 font-normal text-sm">{validations?.facebook}</p>
          )}
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 mr-2">
              <img src={SPOTIFY_ICON} alt='instagramIcon' />
            </div>
            <input
              name="spotify"
              placeholder="www.Spotify.Com"
              onChange={handleInputChange}
              value={formData?.spotify}
              className=" w-full py-[16px] pl-12 pr-[8px] rounded-[8px] border !border-[#0FA845] focus:!border-[#0FA845] focus:outline-none"
            />
          </div>
          {validations?.spotify && (
            <p className="text-red-400 mt-2 font-normal text-sm">{validations?.spotify}</p>
          )}
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 mr-2">
              <img src={SOUNDCLOUD_ICON} alt='instagramIcon' />
            </div>
            <input
              name="soundCloud"
              placeholder="www.soundCloud.Com"
              onChange={handleInputChange}
              value={formData?.soundCloud}
              className="w-full py-[16px] pl-12 pr-[8px] rounded-[8px] border !border-[#FF5500] focus:!border-[#FF5500] focus:outline-none"
            />
          </div>
          {validations?.soundCloud && (
            <p className="text-red-400 mt-2 font-normal text-sm">{validations?.soundCloud}</p>
          )}
          {/* <p classNam e='text-lg font-medium' >Thank you for submitting the Collab form. Your information is important to us.Our team will carefully review it, and the process may take up to 24 hours. We appreciate your patience.</p> */}
          <div className="flex justify-end gap-2 my-3">
            <Button style={{ border: "0" }} type="submit" variant="secondary" onClick={(e) => {
              e.preventDefault();
              navigate("/collaborators")
            }
            }>
              Cancel
            </Button>
            <Button style={{ border: "0" }} type="submit" onClick={handleAddButton}>
              Add
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
};

AddCollaborators.propTypes = {
  handleModalOpen: PropTypes.func,
  onCollabIdReceived: PropTypes.func,
  isCollabReg: PropTypes.bool,
  setActiveStep: PropTypes.func,
};

export default AddCollaborators;
