// Path constants
export const HOME_PATH = "/";
export const LOGIN_PATH = "/login";
export const FORGOT_PASSWORD_PATH = "/forgot_password";
export const RESET_PASSWORD_PATH = "/reset_password";
export const DASHBOARD_PATH = "/dashboard";
export const USER_MANAGEMENT_PATH = "/user_management";
export const MERCHANDISE_PATH = "/merchandise";
export const ADD_PRODUCT_PATH = "/merchandise/add_product";
export const COLLABOARTORS_PATH = "/collaborators";
export const COLLABOARTOR_DETAILS_PATH = "/collaboratorDetails/:id";
export const ADD_COLLABOARTORS_PATH = "/collaborators/add_collaborators";
export const EDIT_COLLABOARTORS_PATH = "/collaborators/edit_collaborators";
export const VIEW_COLLABOARTORS_PATH = "/collaborators/view_collaborators";
export const ORDER_MANAGEMENT_PATH = "/order_management";
export const EDIT_ORDER_PATH = "/order_management/order-details";
export const EVENT_PATH = "/events";
export const VIEW_EVENT_PATH = "/events/view_event";
export const ADD_EVENT_PATH = "/events/add_event";
export const COMMUNITY_PATH = "/community_management";
export const MUSIC_MANAGEMENT_PATH = "/music_management";
export const FINANCE_MANAGEMENT_PATH = "/finance_management";
export const CONTENT_MANAGEMENT_PATH = "/content_management";
export const ADD_BLOG_PATH = "/content_management/add_blog";
export const VIEW_BLOG_PATH = "/content_management/view_blog";
export const VIEW_ORDER_PATH = "/order_management/order-details/:id";
export const PROMO_CODE_PATH = "/promo_code";
export const ADD_PROMO_CODE_PATH = "/promo_code/add_promo_code";
export const MY_PROFILE_PATH = "/my_profile";



export const PRODUCT_ADD_PATH_2 = "/content_management/addProduct";





