import { Avatar, TableCell } from "@mui/material";
import axios from "axios";

export const readFile = (file) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
};

// To get the Nested Properties values ---
export const getNestedProperty = (obj, key) => {
	return key.split(".").reduce((o, k) => (o || {})[k], obj);
};

// To format Time ---
export const formatDate = (timestamp) => {
	const dateObject = new Date(timestamp);
	return dateObject.toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
	});
};

//to format time
export const formatTime = (timestamp) => {
	const dateObject = new Date(timestamp);
	return dateObject.toLocaleTimeString("en-GB", {
		hour: "2-digit",
		minute: "2-digit",
		hour12: true,
	});
};
// to format date and time
// to format date and time
export const formatDateTime = (timestamp) => {
	const dateObject = new Date(timestamp);

	const formattedDate = formatDate(timestamp);

	const formattedTime = dateObject.toLocaleTimeString("en-GB", {
		hour: "2-digit",
		minute: "2-digit",
		hour12: true,
	});

	return `${formattedDate} ${formattedTime}`;
};

export const formatTimePlayer = (time) => {
	if (time && !isNaN(time)) {
		const minutes = Math.floor(time / 60);
		const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
		const seconds = Math.floor(time % 60);
		const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
		return `${formatMinutes}:${formatSeconds}`;
	}
	return "00:00";
};

// table heading
export const renderTableHeading = (content, style = {}) => (
	<TableCell
		key={content}
		style={{
			fontFamily: "Poppins",
			fontSize: "14px",
			fontWeight: 500,
			color: "#060709",
			...style,
		}}
	>
		{content}
	</TableCell>
);
// render table cell content
export const renderTableCell = (content, style = {}) => (
	<TableCell
		style={{
			fontFamily: "Poppins",
			width: "400px",
			maxWidth: "400px",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",

			fontSize: "16px",
			fontWeight: 400,
			color: "#858585",
			...style,
		}}
	>
		{content}
	</TableCell>
);

// render static data
export const renderTextContent = (content, style = {}) => (
	<p
		style={{
			fontFamily: "Poppins",
			width: "fit-content",
			fontStyle: "italic",
			fontSize: "14px",
			fontWeight: 400,
			color: "#858585",
			...style,
		}}
	>
		{content}
	</p>
);

// render initials
export const stringAvatar = (name) => {
	const nameParts = name.split(" ");
	const initials = nameParts
		.map((part) => part.charAt(0).toUpperCase())
		.join("");
	return {
		children: initials,
	};
};
// Render action buttons
export const renderNameWithProfile = (name, image, product) => {
	let avatar;

	if (product && image) {
		avatar = (
			<Avatar src={image} sx={{ width: 46, height: 46 }} variant="rounded" />
		);
	} else if (!product) {
		avatar = (
			<Avatar
				{...stringAvatar(name)}
				src={image}
				sx={{ width: 46, height: 46 }}
				variant="rounded"
			/>
		);
	}

	return (
		<div className="flex gap-2 items-center">
			{avatar}
			{name}
		</div>
	);
};

export const getMediaDuration = (file, setDuration) => {
	const url = URL.createObjectURL(file);
	const mediaElement = document.createElement(
		file.type.includes("audio") ? "audio" : "video"
	);
	mediaElement.src = url;
	mediaElement.onloadedmetadata = () => {
		setDuration(mediaElement.duration);
	};
};

export const extractYouTubeVideoId = (url) => {
	try {
		// Check if the URL starts with 'http' or 'https', if not, prepend 'https://'
		if (!/^https?:\/\//i.test(url)) {
			url = "https://" + url;
		}
		// Create a URL object to parse the given URL
		// debugger;
		const parsedUrl = new URL(url);
		// console.log(parsedUrl, "YOUTUBE CHECK");
		// Normalize the host by removing the 'www.' prefix if it exists
		const hostWithoutWWW = parsedUrl.host.replace(/^www\./, "");
		// Allowed YouTube hosts (without 'www.')
		const allowedHosts = ["youtube.com", "youtu.be", "m.youtube.com"];
		// Check if the normalized host is in the list of allowed hosts
		if (!allowedHosts.includes(hostWithoutWWW)) {
			return null;
		}
		// ensure none of the allowedHosts exists more than once in the url
		for (const host of allowedHosts) {
			// If the host occurs more than once in the original URL string, return null
			const hostRegex = new RegExp(host, "g");
			const occurrences = (url.match(hostRegex) || []).length;
			if (occurrences > 1) {
				return null;
			}
		}
		// Extract video ID based on the host
		let videoId = null;
		// Case 1: Handle URLs like "https://www.youtube.com/watch?v=xti_6Bzpa8A"
		if (
			hostWithoutWWW === "youtube.com" ||
			hostWithoutWWW === "m.youtube.com"
		) {
			// Check if the path is "/watch"
			if (parsedUrl.pathname === "/watch") {
				// Get the "v" query parameter (video ID)
				videoId = parsedUrl.searchParams.get("v");
			} else if (parsedUrl.pathname.startsWith("/list")) {
				// You can optionally handle playlist URLs separately if needed
				// for now, ignore them
				return null;
			}
		}
		// Case 2: Handle URLs like "https://youtu.be/xti_6Bzpa8A"
		if (hostWithoutWWW === "youtu.be") {
			// Extract the video ID from the pathname
			videoId = parsedUrl.pathname.slice(1); // Remove the leading "/"
		}
		// Ensure the video ID is valid (YouTube video IDs are 11 characters)
		if (videoId && videoId.length === 11) {
			return videoId;
		}
		// If the video ID is invalid or not found, return null
		return null;
	} catch (error) {
		// In case of any errors (invalid URL, etc.), return null
		// console.error(error?.message, "ERROR IN YOUTUBE CHECK");
		return null;
	}
};

export const getYouTubeVideoDuration = async (videoId) => {
	const apiKey = process.env.REACT_APP_YOUTUBE_API; // Replace with your YouTube API key
	const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=contentDetails&key=${apiKey}`;
	try {
		const response = await axios.get(apiUrl);
		const duration = response.data.items[0].contentDetails.duration;
		return duration;
	} catch (error) {
		throw error;
	}
};

export const parseYouTubeDuration = (duration) => {
	if (!duration) return 0;
	const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
	const hours = (parseInt(match[1]) || 0) * 3600;
	const minutes = (parseInt(match[2]) || 0) * 60;
	const seconds = parseInt(match[3]) || 0;

	// Total duration in seconds
	const totalSeconds = hours + minutes + seconds;
	return totalSeconds;
};

export const generateVideoThumbnail = (file, setVideoThumbnailFile) => {
	return new Promise((resolve, reject) => {
		const videoElement = document.createElement("video");
		const url = URL.createObjectURL(file);

		videoElement.src = url;

		videoElement.onloadedmetadata = () => {
			// Seek to a random time in the video for the thumbnail
			const randomTime = Math.random() * videoElement.duration;
			videoElement.currentTime = randomTime;
		};

		videoElement.onseeked = () => {
			const canvas = document.createElement("canvas");
			canvas.width = videoElement.videoWidth;
			canvas.height = videoElement.videoHeight;
			const ctx = canvas.getContext("2d");
			ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

			canvas.toBlob((blob) => {
				const thumbnailFile = new File([blob], `${file.name}_thumbnail.png`, {
					type: "image/png",
				});

				setVideoThumbnailFile(thumbnailFile);
				resolve(thumbnailFile);

				URL.revokeObjectURL(url);
			}, "image/png");
		};

		videoElement.onerror = (error) => {
			reject(new Error("Error generating thumbnail"));
		};
	});
};

export const formDataConverter = (data) => {
	const formData = new FormData()
	for (let key in data) {
		if (data[key] !== undefined && data[key] !== null) {
			formData.append(key, data[key])
		} else {
			delete data[key];
		}
	}
	return formData
}
export function NA(data) {
	if (data) {
		return data
	} else {
		return "N/A"
	}
}