import React from 'react';

const UploadIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.55 9H13.5V15.25C13.5 15.66 13.16 16 12.75 16C12.34 16 12 15.66 12 15.25V9H7.95C4.75 9 2.75 11 2.75 14.2V16.79C2.75 20 4.75 22 7.95 22H17.54C20.74 22 22.74 20 22.74 16.8V14.2C22.75 11 20.75 9 17.55 9Z"
        fill="#E6355C"
      />
      <path
        d="M13.4998 4.56043L15.5698 6.63043C15.7198 6.78043 15.9098 6.85043 16.0998 6.85043C16.2898 6.85043 16.4798 6.78043 16.6298 6.63043C16.9198 6.34043 16.9198 5.86043 16.6298 5.57043L13.2798 2.22043C12.9898 1.93043 12.5098 1.93043 12.2198 2.22043L8.86984 5.57043C8.57984 5.86043 8.57984 6.34043 8.86984 6.63043C9.15984 6.92043 9.63984 6.92043 9.92984 6.63043L11.9998 4.56043V9.00043H13.4998V4.56043Z"
        fill="#E6355C"
      />
    </svg>
  );
};

export default UploadIcon;
