import { useState, useEffect, useContext, useCallback } from "react";
import { motion } from "framer-motion";
import { CircularProgress, Skeleton } from "@mui/material";
import Ticker from "react-ticker";
import styles from "./Player.module.css";
import {
	formatTimePlayer,
	extractYouTubeVideoId,
} from "../../utils/helperFuncs";
import MiniVideo from "./Video/MiniVideo/MiniVideo";
import NewVideoModal from "./Video/NewVideoModal/NewVideoModal";
import YouTube from "react-youtube";
import { PlayerContext } from "./MusicManagement";
import PrimaryControls from "../../components/music/PrimaryControls/PrimaryControls";
import ProgressBar from "../../components/music/ProgressBar/ProgressBar";

function Player() {
	const {
		loadMedia,
		timeProgress,
		setTimeProgress,
		duration,
		play,
		setPlay,
		repeat,
		setRepeat,
		shuffle,
		setShuffle,
		showVideo,
		setShowVideo,
		currentTrack,
		audioRef,
		ytState,
		setYTState,
		progressBarRef,
		vidProgressBarRef,
		playAnimationRef,
		handleBack,
		handleForward,
		prev,
		next,
		shuffleFunc,
		ytref,
		playerCleanup,
		onLoadedMetadata,
		videoRef,
		repeatAni,
	} = useContext(PlayerContext);

	const [forceRender, setForceRender] = useState(false);

	useEffect(() => {
		setForceRender((prev) => !prev);
	}, [currentTrack]);

	const handleProgressChange = () => {
		if (loadMedia) return;
		if (!currentTrack?.isYoutube)
			audioRef.current.currentTime = progressBarRef.current.value;
		else ytref.current?.seekTo(progressBarRef.current.value, true);
		const progressPercent =
			(progressBarRef.current.value / duration) * 100 +
			(progressBarRef.current.value !== duration && 0.25);
		progressBarRef.current.style.setProperty(
			"--range-progress",
			`${progressPercent}%`
		);
	};

	useEffect(() => {
		if (currentTrack) {
			if (!currentTrack.isYoutube && audioRef.current) {
				if (play) {
					audioRef.current.play();
					playAnimationRef.current = requestAnimationFrame(repeatAni);
				} else {
					audioRef.current.pause();
					cancelAnimationFrame(playAnimationRef.current);
				}
			}
		}
	}, [play, currentTrack, repeatAni]);

	return (
		<>
			{currentTrack?.isVideoOnly === true && (
				<NewVideoModal forceRender={forceRender} />
			)}

			<motion.div
				initial={{ y: 60 }}
				animate={{ y: 0 }}
				transition={{ ease: "easeOut", duration: 0.75 }}
				className={`min-h-[4.75rem] w-full absolute bottom-0 left-0 px-8 py-2 bg-[#E6355C] text-cyan-50 z-10 flex ${
					currentTrack?.isVideoOnly && showVideo
						? "justify-center"
						: "justify-between"
				}`}
			>
				{!loadMedia && !currentTrack ? (
					<>Error in Music...</>
				) : (
					<>
						<div
							className={`max-h-full flex items-center gap-x-2 ${
								currentTrack?.isVideoOnly && showVideo ? "" : "w-52"
							}`}
						>
							{currentTrack ? (
								<>
									<img
										src={
											currentTrack?.isYoutube
												? currentTrack?.youtube?.thumbnail
												: currentTrack?.audio?.thumbnail
										}
										alt={`${currentTrack?.title}`}
										style={{ borderRadius: "50%" }}
										className="w-9 h-9 object-cover border-solid border-[1px] border-[white]"
									/>
									<div className="flex-1 h-full grow flex flex-col justify-between overflow-hidden">
										{currentTrack?.title.length > 10 &&
										!(currentTrack?.isVideoOnly && showVideo) ? (
											<>
												{/* Ticker text with flex-grow and padding between ticker instances */}
												<div className="flex-grow">
													<Ticker key={currentTrack?.title}>
														{() => (
															<span className="whitespace-nowrap px-4 text-base font-semibold">
																{currentTrack?.title}
															</span>
														)}
													</Ticker>
												</div>
											</>
										) : (
											<span className="max-w-full block text-base font-semibold truncate">
												{currentTrack?.title}
											</span>
										)}
										{/* Artist text with flex-grow */}
										<div className="flex-grow">
											<span className="block text-sm font-normal truncate">
												by {`${currentTrack?.artist?.firstName} `}
												{currentTrack?.artist.lastName}
											</span>
										</div>
									</div>
								</>
							) : (
								<>
									<Skeleton
										variant="circular"
										sx={{
											bgcolor: "#CF254B",
											width: "2.25rem",
											height: "2.25rem",
										}}
									/>
									<div className="flex flex-col gap-y-[0.5rem]">
										<Skeleton
											variant="rounded"
											sx={{
												bgcolor: "#CF254B",
												width: "6rem",
												height: "0.75rem",
											}}
										/>
										<Skeleton
											variant="rounded"
											sx={{
												bgcolor: "#CF254B",
												width: "3.5rem",
												height: "0.75rem",
											}}
										/>
									</div>
								</>
							)}
						</div>
						<div
							className={`flex shrink-0 grow ${
								currentTrack?.isVideoOnly && showVideo && "hidden"
							}`}
						>
							<div className="w-full flex justify-between items-center gap-x-4">
								<span className="min-w-[45px]">
									{currentTrack && formatTimePlayer(timeProgress)}
								</span>
								<ProgressBar referenceHook={progressBarRef} />
								<span className="min-w-[45px]">
									{currentTrack && formatTimePlayer(duration)}
								</span>
							</div>
						</div>

						{(!currentTrack?.isVideoOnly ||
							(currentTrack?.isVideoOnly && !showVideo)) && <PrimaryControls />}

						{/*AUDIO, VIDEO, & YOUTUBE DOM*/}

						{!currentTrack?.isYoutube && currentTrack?.audio?.src && (
							<audio
								loop={repeat}
								key={currentTrack?.audio?.src}
								autoplay
								src={currentTrack?.audio?.src}
								ref={audioRef}
								onLoadedMetadata={(e) => onLoadedMetadata(e.target.duration)}
								onEnded={() => {
									if (!repeat) {
										playerCleanup();
										if (shuffle) shuffleFunc();
										else handleForward();
									}
								}}
							/>
						)}

						{showVideo &&
							!currentTrack?.isYoutube &&
							currentTrack?.isVideoOnly !== true && <MiniVideo />}

						{currentTrack?.isYoutube && (
							<YouTube
								key={forceRender}
								opts={{ width: "512", height: "320" }}
								style={{
									position: "absolute",
									[showVideo ? "bottom" : "top"]: "100%",
									right: "0",
								}}
								onReady={(e) => {
									onLoadedMetadata(e.target.getDuration());
									ytref.current = e.target;
									ytref.current.playVideo();
								}}
								onStateChange={(e) => {
									setYTState(e.data);
									if (e.data === 1) {
										// console.log("RA: YOUTUBE DESKTOP PLAYED");
										playAnimationRef.current = requestAnimationFrame(repeatAni);
									} else {
										cancelAnimationFrame(playAnimationRef.current);
									}
								}}
								videoId={extractYouTubeVideoId(currentTrack?.youtube?.url)}
								onPlay={() => {
									setPlay(true);
								}}
								onPause={() => {
									setPlay(false);
								}}
								onEnd={() => {
									if (repeat) {
										ytref.current?.seekTo(0);
										ytref.current?.playVideo();
									} else {
										playerCleanup();
										if (shuffle) shuffleFunc();
										else handleForward();
									}
								}}
							/>
						)}
					</>
				)}
			</motion.div>
		</>
	);
}

export default Player;
