export const IMAGE_BASE_URL = process.env.PUBLIC_URL;

export const LOGO = IMAGE_BASE_URL + "/assets/images/logo.png";
export const VIDEO_THUMB = IMAGE_BASE_URL + "/assets/images/video_thumb.png";
export const IMG_CARD = IMAGE_BASE_URL + "/assets/images/imgcard.png";
export const EVENT_BANNER = IMAGE_BASE_URL + "/assets/images/event-banner.jpg";
export const TRASH = IMAGE_BASE_URL + "/assets/images/trash.svg";
export const BACK_ARROW=IMAGE_BASE_URL + "/assets/images/backArrow.svg";
export const GALLERY_ICON=IMAGE_BASE_URL + "/assets/images/galleryIcon.svg";
export const FACEBOOK_ICON=IMAGE_BASE_URL + "/assets/images/facebookIcon.svg";
export const INSTAGRAM_ICON=IMAGE_BASE_URL + "/assets/images/instagramIcon.svg";
export const SPOTIFY_ICON=IMAGE_BASE_URL + "/assets/images/spotifyIcon.svg";
export const SOUNDCLOUD_ICON=IMAGE_BASE_URL + "/assets/images/soundCloudIcon.svg";
export const DUMMY_COLLAB_PIC=IMAGE_BASE_URL + "/assets/images/dummyCollabProfile.svg";
export const ARROW_LEFT=IMAGE_BASE_URL + "/assets/images/arrowLeftIcon.svg";

