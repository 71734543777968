import Joi from "joi";

export const AddCollaboratorValidations = Joi.object({
    firstName: Joi.string().required().max(20).label("First Name"),
    lastName: Joi.string().required().max(20).label("Last Name"),
    email: Joi.string().email({ tlds: { allow: false } }).required().label("Email Address"),
    mobileNumber: Joi.string()
        .pattern(/^\d{11}$/)
        .required()
        .messages({
            'string.pattern.base': 'Please enter valid phone number',
            'any.required': 'Phone Number is required.'
        })
        .label("Phone Number"),
    collabType: Joi.string().required().label("Collab Type"),
    Instagram: Joi.string()
        .pattern(/^https?:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9._-]+\/?$/)
        .label("Instagram")
        .messages({
            "string.pattern.base": `"Instagram" must be a valid Instagram URL (e.g., https://instagram.com/username).`
        }),
    facebook: Joi.string()
        .pattern(/^https?:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9.]+\/?$/)
        .label("Facebook")
        .messages({
            "string.pattern.base": `"Facebook" must be a valid Facebook URL (e.g., https://facebook.com/username).`
        }),
    spotify: Joi.string()
        .pattern(/^https?:\/\/(open\.spotify\.com\/user\/[a-zA-Z0-9]+|spotify:user:[a-zA-Z0-9]+)$/)
        .label("Spotify")
        .messages({
            "string.pattern.base": `"Spotify" must be a valid Spotify user profile URL (e.g., https://open.spotify.com/user/username or spotify:user:username).`
        }),
    soundCloud: Joi.string()
        .pattern(/^https?:\/\/(www\.)?soundcloud\.com\/[a-zA-Z0-9_-]+\/?$/)
        .label("SoundCloud")
        .messages({
            "string.pattern.base": `"SoundCloud" must be a valid SoundCloud URL (e.g., https://soundcloud.com/username).`
        }),
})

export const AddProductValidations = Joi.object({
    name: Joi.string().required().messages({
        'string.empty': 'Please Enter Product Name',
        'any.required': 'Please Enter Product Name'
    }),
    description: Joi.string().required().messages({
        'string.empty': 'Please Enter Product Description',
        'any.required': 'Please Enter Product Description'
    }),
    category: Joi.string().required().messages({
        'string.empty': 'Please Select a Category',
        'any.required': 'Please Select a Category',
        'string.base': 'Please Select a Category'
    }),
    productType: Joi.string().required().messages({
        'string.empty': 'Please Select a Product Type',
        'any.required': 'Please Select a Product Type',
        'string.base': 'Please Select a Product Type'

    }),
    artType: Joi.string().when('productType', {
        is: "general",
        then: Joi.required().messages({
            'string.empty': 'Please Select an Art Type',
            'any.required': 'Please Select an Art Type',
            'string.base': 'Please Select an Art Type'
        }),
        otherwise: Joi.allow("").optional()
    }).messages({
        'string.base': 'Invalid Art Type'
    }),
    productArr: Joi.array().min(1).required().messages({
        'array.min': 'Please Upload At Least One Image Of The Product',
        'any.required': 'Please Upload At Least One Image Of The Product'
    }),
    videoArr: Joi.array().optional(),
    sizeGuide: Joi.array().min(1).required().messages({
        'array.min': 'Please Upload Size Guide For The Product',
        'any.required': 'Please Upload Size Guide For The Product'
    }),
    coordinates: Joi.array().when('productType', {
        is: 'custom',
        then: Joi.array().required().messages({
            'array.base': 'Please Specify Area for Custom Product',
            'any.required': 'Please Specify Area for Custom Product'
        }),
        otherwise: Joi.array().optional()
    }),
    basePrice: Joi.number().required().messages({
        'any.empty': 'Please Enter Base Price',
        'any.required': 'Please Enter Base Price'
    })
});


export const AddPromoCodeValidations = Joi.object({
    promoName: Joi.string().required().messages({
        'any.required': 'Please Enter Promo Code Name',
        'string.empty': 'Please Enter Promo Code Name'
    }),
    promoCode: Joi.string().required().messages({
        'any.required': 'Please Enter Promo Code',
        'string.empty': 'Please Enter Promo Code'
    }),
    minPrice: Joi.number().required().messages({
        'any.required': 'Please Enter Minimum Price',
        'number.base': 'Please Enter a Valid Number for Minimum Price',
        'number.empty': 'Please Enter Minimum Price'
    }),
    discount: Joi.number().required().max(Joi.ref('minPrice')).messages({
        'any.required': 'Please Enter Discount',
        'number.base': 'Please Enter a Valid Number for Discount',
        'number.empty': 'Please Enter Discount',
        'number.max': 'Discount Offered Price should not be greater than the Minimum Price'
    })
});

export const AddEventValidations = Joi.object({
    eventName: Joi.string().required().messages({
        'any.required': 'Please Enter Event Name',
        'string.empty': 'Please Enter Event Name',
    }),
    eventArtistName: Joi.string().required().messages({
        'any.required': 'Please Enter Artist Name',
        'string.empty': 'Please Enter Artist Name',
    }),
    eventAddress: Joi.string().required().messages({
        'any.required': 'Please Enter Event Address',
        'string.empty': 'Please Enter Event Address',
    }),
    eventDate: Joi.date().iso().required().messages({
        'any.required': 'Event date is required',
        'date.format': 'Event date is required',
        "date.base": 'Event date is required'
    }),
    eventStartDate: Joi.date().iso().required().messages({
        'any.required': 'Event start time is required',
        'date.format': 'Event start time is required',
        "date.base": 'Event start time is required'
    }),
    eventEndDate: Joi.date().iso().required().greater(Joi.ref('eventStartDate')).messages({
        'any.required': 'Event end time is required',
        'date.format': 'Event end time is required',
        "date.base": 'Event start end is required',
        'date.greater': 'Event end time must be after event start time',
    }),
    eventDetails: Joi.string()
        .max(600)
        .required()
        .messages({
            'any.required': 'Please Enter Event Details',
            'string.empty': 'Please Enter Event Details',
            'string.max': 'Event Details cannot be more than 600 characters'
        }),
});