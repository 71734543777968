import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import Player from "react-player/file";
import { PlayerContext } from "../../MusicManagement";
import { CircularProgress, Modal, Box } from "@mui/material";
import { formatTimePlayer } from "../../../../utils/helperFuncs";
import ProgressBar from "../../../../components/music/ProgressBar/ProgressBar";
import PrimaryControls from "../../../../components/music/PrimaryControls/PrimaryControls";

const NewVideoModal = ({ forceRender }) => {
	const {
		videoRef,
		play,
		setPlay,
		setOpen,
		currentTrack,
		setCurrentTrack,
		setYTState,
		handleForward,
		handleBack,
		loadMedia,
		playerCleanup,
		repeat,
		setRepeat,
		shuffle,
		shuffleFunc,
		prev,
		next,
		onLoadedMetadata,
		vidProgressBarRef,
		duration,
		ytState,
		timeProgress,
		playAnimationRef,
		setShowVideo,
		showVideo,
		repeatAni,
		buffering,
		setBuffering,
	} = useContext(PlayerContext);

	const pauseFunc = () => {
		setPlay(false);
		videoRef.current.getInternalPlayer().pause();
		cancelAnimationFrame(playAnimationRef.current);
	};

	return (
		<Modal
			open={true}
			onClose={() => {
				setShowVideo(false);
				pauseFunc();
			}}
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				visibility: showVideo ? "visible" : "hidden", // Visibility control
			}}
		>
			<Box
				className="relative bg-[#1c1c1e] rounded-lg"
				sx={{
					width: "75vw",
					height: `calc(75vw * (${window.innerHeight} / ${window.innerWidth}))`,
					outline: "none", // Remove default MUI modal outline
					position: "relative",
				}}
			>
				<svg
					width="56"
					height="56"
					viewBox="0 0 56 56"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					onClick={() => {
						setShowVideo(false);
						pauseFunc();
					}}
					className="absolute -top-[2.5%] -right-[2.5%] cursor-pointer z-30"
				>
					<circle cx="28" cy="28" r="16" fill="white" />
					<path
						d="M27.9994 4.66602C15.1427 4.66602 4.66602 15.1427 4.66602 27.9994C4.66602 40.856 15.1427 51.3327 27.9994 51.3327C40.856 51.3327 51.3327 40.856 51.3327 27.9994C51.3327 15.1427 40.856 4.66602 27.9994 4.66602ZM35.8393 33.366C36.516 34.0427 36.516 35.1627 35.8393 35.8393C35.4893 36.1894 35.046 36.3527 34.6027 36.3527C34.1594 36.3527 33.716 36.1894 33.366 35.8393L27.9994 30.4727L22.6327 35.8393C22.2827 36.1894 21.8393 36.3527 21.396 36.3527C20.9527 36.3527 20.5094 36.1894 20.1594 35.8393C19.4827 35.1627 19.4827 34.0427 20.1594 33.366L25.526 27.9994L20.1594 22.6327C19.4827 21.956 19.4827 20.836 20.1594 20.1594C20.836 19.4827 21.956 19.4827 22.6327 20.1594L27.9994 25.526L33.366 20.1594C34.0427 19.4827 35.1627 19.4827 35.8393 20.1594C36.516 20.836 36.516 21.956 35.8393 22.6327L30.4727 27.9994L35.8393 33.366Z"
						fill="#E6355C"
					/>
				</svg>
				<div
					className="w-full h-full relative"
					// onClick={() => {
					// 	if (play) {
					// 		pauseFunc();
					// 	} else {
					// 		if (!buffering) {
					// 			setPlay(true);
					// 			videoRef.current.getInternalPlayer().play();
					// 			playAnimationRef.current = requestAnimationFrame(repeatAni);
					// 		}
					// 	}
					// 	// if (!buffering) {
					// 	// 	setPlay((curr) => !curr);
					// 	// 	if (play) {
					// 	// 		videoRef.current.getInternalPlayer().pause();
					// 	// 		cancelAnimationFrame(playAnimationRef.current);
					// 	// 	} else {
					// 	// 		videoRef.current.getInternalPlayer().play();
					// 	// 		playAnimationRef.current = requestAnimationFrame(repeatAni);
					// 	// 	}
					// 	// }
					// }}
					onClick={() => {
						if (!buffering) {
							setPlay((curr) => !curr);
							if (play) {
								// console.log("CA: DIV PAUSE");
								cancelAnimationFrame(playAnimationRef.current);
								videoRef.current.getInternalPlayer().pause();
							} else {
								// console.log("RA: DIV PLAY");
								videoRef.current.getInternalPlayer().play();
								playAnimationRef.current = requestAnimationFrame(repeatAni);
							}
						}
					}}
				>
					{buffering && (
						<div
							className="absolute inset-0 flex items-center justify-center"
							style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
						>
							<CircularProgress style={{ color: "white" }} />
						</div>
					)}
					{/* Container with Border Radius and Overflow Hidden */}
					<div
						style={{
							borderRadius: "12px",
							overflow: "hidden",
							width: "100%",
							height: "100%",
						}}
					>
						<Player
							key={forceRender}
							onBuffer={() => {
								// console.log("CA: ENTERED ON BUFFER");
								setBuffering(true);
								cancelAnimationFrame(playAnimationRef.current);
								// pauseFunc();
							}}
							onBufferEnd={() => {
								// console.log("EXITED ON BUFFER");
								setBuffering(false);
								if (play) {
									// console.log("RA: BUFFER END PLAY");
									playAnimationRef.current = requestAnimationFrame(repeatAni);
								} else {
									// console.log("CA: BUFFER END PAUSE");
									pauseFunc();
								} // just for the sake of it cuz why the heck not
							}}
							ref={videoRef}
							url={
								currentTrack?.video?.src?.startsWith(
									"https://aeternal-lovers-hls"
								)
									? currentTrack?.video?.src + "720.m3u8"
									: currentTrack?.video?.src
							}
							playing={play}
							// onPlay={() => {
							// 	console.log("RA: NEW VIDEO ON PLAY");
							// 	playAnimationRef.current = requestAnimationFrame(repeatAni);
							// }}
							onPause={() => {
								// console.log("CA: NEW VIDEO ON PAUSE");
								cancelAnimationFrame(playAnimationRef.current);
							}}
							controls={false} // Disable default controls to customize
							width="100%"
							height="100%"
							config={{
								file: {
									forceHLS: currentTrack?.video?.src?.startsWith(
										"https://aeternal-lovers-hls"
									), // Enable HLS for m3u8 files
									attributes: {
										poster: currentTrack?.video?.thumbnail,
										onLoadedMetadata: (event) => {
											setBuffering(false);
											onLoadedMetadata(event.target.duration);
										},
									},
								},
							}}
							style={{
								objectFit: "contain",
								backgroundColor: "black",
							}}
							loop={repeat}
							onEnded={() => {
								playerCleanup();
								if (shuffle) shuffleFunc();
								else handleForward();
							}}
						/>
					</div>
				</div>
				<div
					className={`w-full absolute bottom-0 left-0 right-0 flex items-center justify-between gap-x-4 p-4 bg-[rgba(0,0,0,0.5)] rounded-br-xl rounded-bl-xl flex-row-reverse`}
				>
					<PrimaryControls />
					<div className="flex w-full justify-between items-center gap-x-4">
						<span className="w-12 text-white">
							{currentTrack && formatTimePlayer(timeProgress)}
						</span>
						<ProgressBar referenceHook={vidProgressBarRef} />
						<span className="w-12 text-white">
							{currentTrack && formatTimePlayer(duration)}
						</span>
					</div>
				</div>
			</Box>
		</Modal>
	);
};

export default NewVideoModal;
