import { React, useEffect, useContext, useRef } from "react";
import { PlayerContext } from "../../MusicManagement";
import ReactPlayer from "react-player/file";

const MiniVideo = () => {
	const { miniVidRef } = useContext(PlayerContext);
	const { currentTrack } = useContext(PlayerContext);

	const isHlsSource = currentTrack?.video?.src?.startsWith(
		"https://aeternal-lovers-hls"
	);

	useEffect(() => {
		if (miniVidRef.current) {
			miniVidRef.current.seekTo(0); // Reset the video to the start when it mounts
		}
	}, [currentTrack]);

	return (
		<div className="w-[32rem] h-[20rem] absolute right-0 bottom-[4.75rem] flex flex-col items-center justify-between bg-[#E6355C] p-2">
			<ReactPlayer
				ref={miniVidRef}
				url={
					currentTrack?.video?.src?.startsWith("https://aeternal-lovers-hls")
						? currentTrack?.video?.src + "720.m3u8"
						: currentTrack?.video?.src
				}
				// playing={play}
				controls
				width="100%"
				height="100%"
				style={{ objectFit: "contain", backgroundColor: "black" }}
				config={{
					file: {
						forceHLS: currentTrack?.video?.src?.startsWith(
							"https://aeternal-lovers-hls"
						),
						attributes: {
							poster: currentTrack?.video?.thumbnail,
						},
					},
				}}
				// onPlay={() => setPlay(true)}
				// onPause={() => setPlay(false)}
			/>
		</div>
	);
};

export default MiniVideo;
