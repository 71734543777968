import { useState, useEffect, useContext, useCallback } from "react";
import { PlayerContext } from "../../../screens/musicmanagement/MusicManagement";
import styles from "./ProgressBar.module.css";

const ProgressBar = ({ referenceHook }) => {
	const {
		// open,
		// setOpen,
		// selectMedia,
		// setSelectMedia,
		loadMedia,
		// setLoadMedia,
		timeProgress,
		setTimeProgress,
		duration,
		// setDuration,
		play,
		setPlay,
		repeat,
		setRepeat,
		shuffle,
		setShuffle,
		showVideo,
		setShowVideo,
		currentTrack,
		audioRef,
		ytState,
		setYTState,
		// progressBarRef,
		handleBack,
		handleForward,
		prev,
		next,
		shuffleFunc,
		ytref,
		playerCleanup,
		onLoadedMetadata,
		videoRef,
	} = useContext(PlayerContext);

	const handleProgressChange = () => {
		if (loadMedia) return;

		const seekTime = parseFloat(referenceHook.current.value);
		if (!currentTrack?.isYoutube) {
			if (videoRef.current) videoRef.current.seekTo(seekTime, "seconds");
			else audioRef.current.currentTime = seekTime;
		} else {
			ytref.current.seekTo(seekTime, true);
		}

		const progressPercent = (seekTime / duration) * 100;
		referenceHook.current.style.setProperty(
			"--range-progress",
			`${progressPercent}%`
		);
	};

	return (
		<div
			style={{
				flexShrink: "0",
				flexGrow: "1",
				height: "1rem",
				position: "relative",
			}}
		>
			<div
				className={styles.emptyInput}
				style={{
					background:
						!loadMedia &&
						(currentTrack.isYoutube
							? ytState === 1 || ytState === 2 || ytState === 3
							: true)
							? "transparent"
							: "#ae1335",
				}}
			/>
			<input
				style={{
					background:
						loadMedia ||
						(currentTrack.isYoutube
							? ytState !== 1 && ytState !== 2 && ytState !== 3
							: false)
							? "transparent"
							: "#ae1335",
					display:
						loadMedia ||
						(currentTrack.isYoutube
							? ytState !== 1 && ytState !== 2 && ytState !== 3
							: false)
							? "none"
							: "block",
				}}
				type="range"
				defaultValue="0"
				onChange={handleProgressChange}
				ref={referenceHook}
			/>
		</div>
	);
};
export default ProgressBar;
