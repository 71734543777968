import React, { useEffect, useState } from "react";
import { Modal, Fade } from "@mui/material";
import Button from "../../../components/formelements/Button";
import PropTypes from "prop-types";
import { Search_User_available } from "../../../utils/api/utilAPI";
import { toast } from "react-toastify";
import { ParaGraph } from "../../../components/title/ParaGraph";
import IconComponent from "../../../components/icons/IconComponent";
import { ICON_LABELS } from "../../../components/icons";
import { TextInput } from "../../../components/formelements/TextInput";
import { searchUsersValidations } from "./MusicModalComponents.jsx/ValidationsUploadMusic";
import AddCollaborators from "../../collaborators/AddCollaboartors";
import MusicUpload from "./MusicModalComponents.jsx/MusicUpload";
import { useNavigate } from "react-router-dom";

const UploadMusicModal = ({ modal, handleClose }) => {
    const [activeStep, setActiveStep] = useState(0); // stepper form states  
    const [searchDetails, setSearchDetails] = useState({
        searchUser: '',
        validationError: '',
        searchData: '',
        buttonText: 'Search',
        isReadOnly: false,
        isError: true,
    });

    const [collabId, setCollabId] = useState("");
    const [isCollabReg, setIsCollabReg] = useState(false);
    const navigate=useNavigate()
    const handleCollabId = (id) => {
        setCollabId(id);
        if (id) {
            setIsCollabReg(true)
        }
    }

    useEffect(() => {
        return () => {
            setSearchDetails({
                searchUser: '',
                validationError: '',
                searchData: '',
                buttonText: 'Search',
                isReadOnly: false
            });
            setActiveStep(0);
        };
    }, [modal]);


    const handleSearchUsers = async () => {
        const { error } = searchUsersValidations.validate({ email: searchDetails.searchUser });
        if (error) {
            setSearchDetails(prevState => ({
                ...prevState,
                validationError: error.details[0].message
            }));
            return;
        }

        try {
            const response = await Search_User_available(searchDetails.searchUser);
            if (response?.data?.data?.length > 0) {
                const userData = response.data?.data;
                const userName = `${userData[0]?.firstName} ${userData[0]?.lastName}`
                if (userData[0]?.isBlocked) {
                    console.log("check if blocked user block is getting executed")
                    setSearchDetails(prevState => ({
                        ...prevState,
                        validationError: `${userName} is blocked. Please unblock to proceed`,
                        isReadOnly: true,
                    }));
                    return;
                }
                setSearchDetails(prevState => ({
                    ...prevState,
                    validationError: `Proceed with uploading music for ${userName}`,
                    searchData: userData,
                    buttonText: 'Upload Music',
                    isReadOnly: true,
                    isError: false
                }));
            } else {
                setSearchDetails(prevState => ({
                    ...prevState,
                    validationError: 'User not found, please proceed with adding a user as Collab',
                    buttonText: 'Add Collaborator',
                    isReadOnly: true,
                    isError: false
                }));
            }
        } catch (error) {
            toast.error('Failed fetching users', {
                toastId: 'fetching-users-for-music-upload'
            });
        }
    };

    const handleNextAction = () => {
        if (searchDetails.buttonText === 'Upload Music') {
            setActiveStep(2);  // Proceed to upload music step
        } else if (searchDetails.buttonText === 'Add Collaborator') {
            // setActiveStep(1);  // Proceed to add collaborator step
            navigate("/collaborators/add_collaborators")
        }
    };

    const handleCancel = () => {
        setSearchDetails({
            searchUser: "",
            validationError: "",
            searchData: "",
            buttonText: "Search",
            isReadOnly: false,
        });
        setActiveStep(0);
    };

    return (
        <Modal
            open={modal}
            onClose={handleClose}
            closeAfterTransition
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Fade in={modal}>
                <div className="scrollContainer bg-white w-[645px] shadow-lg overflow-y-auto max-h-[96vh]">
                    <div className="bg-[#E6355C] px-4 py-2 flex justify-between items-center mb-4">
                        <div>
                            <ParaGraph
                                text="Upload Your own Music"
                                fontsize="18px"
                                fontWeight="600"
                                color="#FFFFFF"
                            />
                            <ParaGraph
                                text="Feel free to upload your beats or music, and we'll reach out to you promptly."
                                fontsize="14px"
                                fontWeight="400"
                                color="#FFFFFF"
                            />
                        </div>
                        <IconComponent
                            iconLabel={ICON_LABELS.CANCEL}
                            color="#ffffff"
                            fontSize="24px"
                            onClick={handleClose}
                            className="cursor-pointer"
                        />
                    </div>

                    {activeStep === 0 &&
                        <div className="py-2 px-[9rem] my-4 min-height-[250px]">
                            <ParaGraph
                                text="Search for a user to upload music"
                                fontsize="18px"
                                color="#000"
                                fontWeight="500"
                                margin="10px 0"
                            />

                            <TextInput
                                type="text"
                                placeholder="Search User Email"
                                style={{ background: '#E4E4E4' }}
                                value={searchDetails.searchUser}
                                onChange={(e) => setSearchDetails(prevState => ({
                                    ...prevState,
                                    searchUser: e.target.value
                                }))}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearchUsers();
                                    }
                                }}
                                error={searchDetails.validationError}
                                isError={searchDetails.isError}
                                isReadOnly={searchDetails.isReadOnly}
                            />
                            <div className="flex items-center space-x-4 mt-1">
                                <Button
                                    className="btn w-[fit-content] mt-1"
                                    type="button"
                                    onClick={searchDetails.isReadOnly ? handleNextAction : handleSearchUsers}
                                >
                                    {searchDetails.buttonText}
                                </Button>

                                {searchDetails.isReadOnly &&
                                    <Button
                                        variant="secondary"
                                        onClick={handleCancel}
                                        className="ml-2 mt-2"
                                    >
                                        Cancel
                                    </Button>
                                }
                            </div>
                        </div>
                    }

                    {/* Add collab component if user doesn't exist */}
                    {/* {activeStep === 1 &&
                        <AddCollaborators onCollabIdReceived={handleCollabId} isCollabReg={isCollabReg} setActiveStep={setActiveStep} prevEmail={searchDetails?.searchUser} />
                    } */}

                    {/* Music upload component if user exists */}
                    {activeStep === 2 && searchDetails.searchData[0]?._id &&
                        <MusicUpload artistId={searchDetails.searchData[0]?._id} handleClose={handleClose} />
                    }

                    {activeStep === 2 && collabId &&
                        <MusicUpload collabId={collabId} handleClose={handleClose} />
                    }

                </div>
            </Fade>
        </Modal>
    );
};

UploadMusicModal.propTypes = {
    modal: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default UploadMusicModal;
